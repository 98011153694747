import React from "react";
import { Box, styled } from "@mui/system";
import { Grid } from "@mui/material";
import { Card } from "@mui/material";
import { Image } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import Carousel from "react-elastic-carousel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import api from "../../api/posts";
import ModalProduct from "./ModalProduct";
import HeadProduct from "./HeadProduct";
import CircularProgress from "@mui/material/CircularProgress";
import pic from "../../image/default_image.png"
import CardMedia from '@mui/material/CardMedia';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 4 },
  { width: 2000, itemsToShow: 4 },
];

const itemSlideShow = [
  { id: 1, name: "slide 1" },
  { id: 1, name: "slide 1" },
  { id: 1, name: "slide 1" },
  { id: 1, name: "slide 1" },
  { id: 1, name: "slide 1" },
];

export default function MainProductItem({ categoryId , setAlert , setMessageAlert }) {


  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    textAlign: "center",
    padding: theme.spacing(1, 0),
    color: "gray",
    backgroundColor: "white",
    // borderRadius: 20,
    height: 350,
  }));

  const [loading, setLoading] = React.useState(true);

  //GET DATA
  const [product, setProduct] = React.useState([]);

  React.useEffect(async () => {
    // product
    if (loading) {
      await api
        .get(`/products/get-productby-category/${categoryId}`)
        .then((res) => {
          setProduct(res?.data);
        });
    }
    setLoading(false);
  }, [loading]);

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  console.log(product);

  return (
    <>
      <Carousel breakPoints={breakPoints} showArrows={false} outerSpacing={-11}>
        {product.map((item) => (
          <RootStyle sx={{ mb: "28px" }}>
            <Box sx={{ width: 280, display: "flex", justifyContent: "center" }}>            

              { item?.image != "" ? 
                  <>
                    <CardMedia
                      component="img"
                      height="190"
                      alt="green iguana"
                      image={`${process.env.React_App_UPLOAD_URL}${item?.image} `}                     
                    />

                  </>

                :
                  <>
                      <CardMedia
                        component="img"
                        height="180"
                        alt="green iguana"
                        image={pic}                
                      /> 
                  </>                   
              }

             
            </Box>
            <Box sx={{ width: 260 , mt:"15px"}}>
              <Grid container spacing={1} display="flex">
                <Grid item xs={12}>
                  <HeadProduct setLoading={setLoading} item={item} setAlert={setAlert} setMessageAlert={setMessageAlert}/>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="left">
                  <Typography
                    className="KhmerOsSiemreap"
                    variant="h6"
                    sx={{
                      color: "#5c31de",
                      ml: "15px",
                    }}
                  >
                    ${Number.parseFloat(item?.price).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="right">
                  <Button
                    className="KhmerOsSiemreap"
                    variant="p"
                    sx={{
                      color: "#fff",
                      background: "red",
                      borderRadius: "10px",
                      p: "4px",
                      "&:hover": {
                        background: "red",
                      },
                    }}
                  >
                    {item?.productType === "NewProduct" ? "New" : "Main"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </RootStyle>
        ))}
      </Carousel>
    </>
  );
}
