import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function LayoutOffer() {
    return (
        <Box>
            <Outlet />
        </Box>
    )
}
