import "./App.css";
import { HashRouter as Routers } from "react-router-dom";
import { createContext, useState } from "react";
import Router from "./Router";
import { Helmet } from "react-helmet";
import { TokenProvider } from "./components/context/tokenContext";

function App() {

  const [token,setToken] = useState(null)

  return (
      <TokenProvider value={token}>
        <div className="App">
          <Helmet>
            <title>Go Global Mart CMS</title>
            <meta name="description" content="App Description" />
            <meta name="theme-color" content="#008f68" />
          </Helmet>
          <Routers>
            <Router setToken={setToken} />
          </Routers>
        </div>
      </TokenProvider>
    
  );
}

export default App;
