import { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Divider } from "@mui/material";
import api from "../api/posts";
import AddForm from "../components/Offer/AddForm";
import { Modal, Stack } from "@mui/material";
import CardOffer from "../components/Offer/CardOffer";
import ChildComponent from "../ChildComponent";
import AlertMessage from "../components/Offer/AlertMessage";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

export default function Offer() {
  let navigate = useNavigate();

  
  //Set Alert
  const [alert, setAlert] = useState(false);
  const [message, setMessage] =useState("");
  const [checkMessage, setCheckMessage] = useState("");

  const [loading,setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [offer, setOffer] = useState([]);
  

  useEffect( async () => {
    
      if(loading){
          await api.get("/offer/").then( res => {
              setOffer(res?.data)
          })
          .catch(err=>{
            // console.log(err.response.data.message)
            if(err.response.data.message === "jwtexpired"){
                setCheckMessage("Account Expired!")
                setAlert(true)   
                setTimeout( () => {
                    window.localStorage.removeItem("accessToken")
                    navigate("/") 
                },2000)             
                              
            }
          })
      }
      setLoading(false)       
      
  }, [loading]);




  if(loading){
    return (
        <Box sx={{ display: "flex", alignItems: "center" , mt:10}} >
            <CircularProgress />
        </Box>
    );
  }


  return (
    <div>
      <ChildComponent title={"Offer"} />
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography Typography variant="h4" sx={{ color: "#472CC9" }}>
              Offers
            </Typography>
          </Grid>
          <Grid item xs={5} display="flex" justifyContent="right">
            <Button
              variant="outlined"
              size="small"
              height="20px"
              onClick={handleOpen}
            >
              <Stack direction="row" spacing={1}>
                <Typography variant="p">Offer +</Typography>
              </Stack>
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <AddForm 
                    setAlert={setAlert} 
                    handleClose={handleClose} 
                    setMessage={setMessage} 
                    setCheckMessage={setCheckMessage}
                    setLoading={setLoading}
              />
            </Modal>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          
          <Grid item xs={12}>
           
              <Grid container spacing={5} >
                {offer.map((item) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <CardOffer 
                            item={item} 
                            setAlert={setAlert}  
                            setMessage={setMessage}  
                            setCheckMessage={setCheckMessage}  
                            setLoading={setLoading} 
                      />
                    </Grid>
                  );
                })}
              </Grid>
            
          </Grid>
        </Grid>
      </Box>
      <AlertMessage alert={alert} message={message} checkMessage={checkMessage} setAlert={setAlert}/>
    </div>
  );
}
