import React from 'react'
import Stack from '@mui/material/Stack';
import { Typography, TextField, Box, Grid, Button } from '@mui/material'
import * as Yup from "yup";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useFormik, Form, FormikProvider } from "formik";
import { withStyles } from "@material-ui/styles";

const CssTextField = withStyles({
    root: {
      "& .MuiInputLabel-root": {
        color: "#5B5BF6",
      },
      "& label.Mui-focused": {
        color: "#5B5BF6",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#5B5BF6",
        },
        "&:hover fieldset": {
          borderColor: "#5B5BF6",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#5B5BF6",
        },
      },
    },
  })(TextField);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
}



export default function AddFounderInfo() {

    const SupplySchema = Yup.object().shape({
        sloganKh: Yup.string().required("Slogan Khmer is required!"),
        sloganEn: Yup.string().required("Slogan English is required!"),
        phoneNumber: Yup.string().required("Phone Number is required!"),
        email: Yup.string().required("Email is required!"),
        addressKh: Yup.string().required("Khmer Location is required!"),
        addressEn: Yup.number().required("English Location is required!"),
    
    });
    
    const formik = useFormik({
        initialValues: {
            // ...editData,
        },
        validationSchema: SupplySchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
    
            console.log(values);
            //create Company Info

       
        },
    });
    
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;


    return (

        <Box sx={style}>
            <Stack spacing={2}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                    sx={{ textAlign: 'center', color: 'secondary' }}>
                    Add Company Info
                </Typography>
                <Box
                    sx={{
                        border: '1px dashed purple',
                        borderRadius: 2,
                        height: '250px',
                        width: '100%',
                    }}
                >

                    <Grid item xs={12} mt={15} align='center'>
                        <PhotoCamera />
                    </Grid>
                    <Typography sx={{ p: 2, textAlign: 'center' }}>

                        Add Profile Image
                    </Typography>

                </Box>
                <Box
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Slogan Khmer"
                            {...getFieldProps("sloganKh")}
                            error={Boolean(touched.sloganKh && errors.sloganKh)}
                            helperText={touched.sloganKh && errors.sloganKh}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Slogan English"
                            {...getFieldProps("sloganEn")}
                            error={Boolean(touched.sloganEn && errors.sloganEn)}
                            helperText={touched.sloganEn && errors.sloganEn}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Phone Number"
                            {...getFieldProps("phoneNumber")}
                            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Email"
                            {...getFieldProps("email")}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Khmer Location"
                            {...getFieldProps("addressKh")}
                            error={Boolean(touched.addressKh && errors.addressKh)}
                            helperText={touched.addressKh && errors.addressKh}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="English Location"
                            {...getFieldProps("addressEn")}
                            error={Boolean(touched.addressEn && errors.addressEn)}
                            helperText={touched.addressEn && errors.addressEn}
                        />
                        </Grid>
                    </Grid>
                
                <Grid item xs={12} md={12} mt={2}>
                    <Button variant="contained" sx={{ p: 2, backgroundColor: '#4838eb' }} fullWidth>Add</Button>
                </Grid>
                </Box>
            </Stack>
        </Box>
    )
}