import React from 'react'
import Stack from '@mui/material/Stack';
import { Typography, TextField, Box, Grid, Button } from '@mui/material'
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { withStyles } from "@material-ui/styles";

const CssTextField = withStyles({
    root: {
      "& .MuiInputLabel-root": {
        color: "#5B5BF6",
      },
      "& label.Mui-focused": {
        color: "#5B5BF6",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#5B5BF6",
        },
        "&:hover fieldset": {
          borderColor: "#5B5BF6",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#5B5BF6",
        },
      },
    },
  })(TextField);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
}


export default function AddFounder({editData}) {

    const SupplySchema = Yup.object().shape({
        missionKhmer: Yup.string().required("Mission Khmer is required!"),
        misstionEnglish: Yup.string().required("Mission English is required!"),
        visionKhmer: Yup.string().required("Vision Number is required!"),
        visionEnglish: Yup.string().required("Vision English is required!"),
        KhmerHistory: Yup.string().required("Khmer History is required!"),
        EnglishHistory: Yup.number().required("English History is required!"),
    
    });
    
    const formik = useFormik({
        initialValues: {
            ...editData,
        },
        validationSchema: SupplySchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
    
            console.log(values);
            //create Company Info

        },
    });
    
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

    return (

        <Box sx={style}>
            <Stack spacing={2}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                    sx={{ textAlign: 'center', color: 'secondary' }}>
                    Add Company Info
                </Typography>

                <Box
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Mission Khmer"
                            {...getFieldProps("missionKhmer")}
                            error={Boolean(touched.missionKhmer && errors.missionKhmer)}
                            helperText={touched.missionKhmer && errors.missionKhmer}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Mission English"
                            {...getFieldProps("misstionEnglish")}
                            error={Boolean(touched.misstionEnglish && errors.misstionEnglish)}
                            helperText={touched.misstionEnglish && errors.misstionEnglish}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Vision Khmer"
                            {...getFieldProps("visionKhmer")}
                            error={Boolean(touched.visionKhmer && errors.visionKhmer)}
                            helperText={touched.visionKhmer && errors.visionKhmer}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Vision English"
                            {...getFieldProps("visionEnglish")}
                            error={Boolean(touched.visionEnglish && errors.visionEnglish)}
                            helperText={touched.visionEnglish && errors.visionEnglish}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="Khmer History"
                            {...getFieldProps("KhmerHistory")}
                            error={Boolean(touched.KhmerHistory && errors.KhmerHistory)}
                            helperText={touched.KhmerHistory && errors.KhmerHistory}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <CssTextField
                            fullWidth
                            label="English History"
                            {...getFieldProps("EnglishHistory")}
                            error={Boolean(touched.EnglishHistory && errors.EnglishHistory)}
                            helperText={touched.EnglishHistory && errors.EnglishHistory}
                        />
                        </Grid>
                    </Grid>
                <Grid item xs={12} md={12} mt={2}>
                    <Button variant="contained" sx={{ p: 2, backgroundColor: '#4838eb' }} fullWidth>Add</Button>
                </Grid>
                </Box>
            </Stack>
        </Box>
    )
}