import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GiChart, GiConsoleController } from "react-icons/gi";
import { BsFillBagFill } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import api from "../../api/posts";
import CircularProgress from "@mui/material/CircularProgress";
import TokenContext from "../context/tokenContext";


const fTitle = { xs: "25px", sm: "25px", md: "20px", lg: "15px", xl: "25px" };
const fText = { xs: "20px", sm: "25px", md: "20px", lg: "15px", xl: "25px" };
const f200 = { xs: "25px", sm: "30px", md: "20px", lg: "20px", xl: "30px" };
const barup = { xs: "95px", sm: "200px", md: "200px", lg: "200px", xl: "200px" };
const colors = "#472CC9";

const Item = styled(Box)(() => ({
  border: "1px solid #472CC9",
  borderRadius: 30,
  height: 340,
  padding: 25,
}));

const Title = styled(Typography)(() => ({
  color: "#797E7F",
  marginTop: 1,
  fontSize: fTitle,
}));

const NextText = styled(Typography)(() => ({
  color: colors,
  fontWeight: "bold",
  marginTop: 10,
}));

const Status = styled(GiChart)(() => ({
  color: colors,
  marginTop: 10,
}));

const TextPro = styled(Typography)(() => ({
  color: colors,
  fontWeight: "bold",
  fontSize: fText,
  marginTop: 10,
}));








export default function Boxinger() {

  const token = React.useContext(TokenContext)


  const [totalVisitor,setTotalVisitor] = React.useState(null);
  const [visitorToday,setVisitorToday] = React.useState(null);

  const [loading,setLoading] = React.useState(true);

  // Product Review
  const [topProduct,setTopProduct] = React.useState([]);
  const [unit,setUnit] = React.useState(null);

  
  // Chart Country
  const [countryCount,setCountryCount] = React.useState(null);
  const [country,setCountry] = React.useState(null);
  
  function countries(countryName) {
    return countryName;
  }
  function countries_count(count) {
    return count;
  }

  // Chart Distric
  const [districtCount,setDistrictCount] = React.useState(null);
  const [district,setDistrict] = React.useState(null);
  
  function districtes(districtesName) {
    if(districtesName === ""){
      districtesName = "unknown"
    }
    return districtesName;
  }
  function districtesCount(countDistrictes) {
    return countDistrictes;
  }

  
  const state = {
          
    series: countryCount,
    options: {

        chart: {
          height: 390,
          type: 'radialBar',
        },

        plotOptions: {
          radialBar: {
            offsetX: 55,
            offsetY: 55,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        colors: ['#FF008E', '#0084ff', '#8946A6', '#1ab7ea'],
        labels: country,
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: -30,
          offsetY: -20,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
          },              
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
                show: false
            }
          }
        }],
    },

};


const state_district = {
          
  series: districtCount,
  options: {

      chart: {        
        height: 390,
        type: 'radialBar',
      },

      plotOptions: {
        radialBar: {
          offsetX: 55,
          offsetY: 55,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 105,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      colors: ['#FF008E', '#0084ff', '#8946A6', '#1ab7ea'],
      labels: district,
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: -30,
        offsetY: -20,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },              
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
              show: false
          }
        }
      }],
  },

};



  React.useEffect( async () => {

    // GET TOTAL VISITOR
    await api.get("/visitors/get-totalvisitor").then( res => {
        console.log(res?.data[0]?.totalVisitor,"totalVisitor")
        if(res?.data.length != 0) {
          setTotalVisitor(res?.data[0]?.totalVisitor);
        } else {
          setTotalVisitor(0);
        }
    })

    // GET TOTAL VISITOR YESTERDAY
    await api.get("/visitors/get-visitorby-day").then( res => {
        console.log(res?.data,"today")
        if(res?.data.length != 0){
          setVisitorToday(res?.data[0]?.totalVisitor);
        } else {
          setVisitorToday(0);
        }
    })

    // GET TOP PRODUCT
    // let getNewProduct = [];
    // await api.get('/products/api/getproduct-reviews').then( res => {
    //   console.log(res?.data , 'product-reviews');      
    //   setTopProduct(res?.data);

    // })

    // GET Country
    let rows = [];
    let rowes = [];
    await api.get('/visitors/get-visitor-by-country').then( res => {
        console.log(res?.data, 'country')
        // country name 
        res?.data.forEach(element => {
            let allRow = countries(
                element._id,
            );

            rows.push(allRow);
            setCountry([...rows]);
        });
        // Count
        res?.data.forEach(element => {
          let allRows = countries_count(
              element.totalVisitor,
          );
          rowes.push(allRows);
          setCountryCount([...rowes]);
        });
      console.log(country);
      console.log(countryCount);
       
    })

    // GET DIstrict
    let rowDistrict = [];
    let rowDistricts = [];
    await api.get('/visitors/get-visitor-by-district').then( res => {
        console.log(res?.data,"distric")
        // district
        res?.data.forEach(element => {
            let allRow = districtes(
                element._id,
            );
            rowDistrict.push(allRow);
            setDistrict([...rowDistrict]);
        });
        // Count
        res?.data.forEach(element => {
          let allRows = districtesCount(
              element.totalVisitor,
          );
          rowDistricts.push(allRows);
          setDistrictCount([...rowDistricts]);
        });
        // console.log(district);
        // console.log(districtCount);
    })
    .catch( err => {
      console.log(err,'errr')
    })
    
    setLoading(false)

  },[loading])

  if(loading){
    return (
      <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <CircularProgress />
        </Box>
    )
  }

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={4} xs={12}>
        <Grid item  xs={12} sm={12} md={6} lg={4} xl={4}  
        display="flex" justifyContent="center" flexDirection="column">

            <Item>
              <Title sx={{ fontSize: fTitle }}>Total Visitors</Title>
              <NextText sx={{ fontSize: f200 }}>{totalVisitor}pax</NextText>
              <Status sx={{ fontSize: barup , ml:20, mt:-5 }} />
            </Item>
          
            {/* <Item>
              <Title sx={{ fontSize: fTitle }}>Total Visitors today</Title>
              <NextText sx={{ fontSize: f200 }}>{visitorToday}pax</NextText>
              <Status sx={{ fontSize: barup }} />
            </Item> */}
           
        </Grid>

        {/* Top PRoduct MAin and New */}

        <Grid item  xs={12} sm={12} md={6} lg={4} xl={4}
              display="flex" justifyContent="center" flexDirection="column">

                  <Item>
                    <Box
                      sx={{ justifyContent: "center", display: "flex" }}
                    > 
                        <div id="chart"  style={{width:"80%"}}>
                            <ReactApexChart                         
                                options={state.options} 
                                series={state.series} 
                                type="radialBar" 
                                // height={390} 
                            /> 
                        </div>                          
                          
                    </Box>
                  </Item>
  
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
        display="flex" justifyContent="center" flexDirection="column">
            <Item>
              <Box
                sx={{ justifyContent: "center", display: "flex" }}
              >
                  <div id="chart" style={{width:"80%"}}>
                      <ReactApexChart                         
                          options={state_district.options} 
                          series={state_district.series} 
                          type="radialBar" 
                          // height={390} 
                      /> 
                  </div>
              </Box>
          </Item>
        </Grid>

    </Grid>


          {/* <Grid item xs={12}>
            <Item>
              <Title sx={{ fontSize: fTitle }}>Main Product</Title>

                  {topProduct.map(( item ) => (
                      <>                
                        <TextPro sx={{ fontSize: fText }} className="KhmerOsSiemreap">
                          <BsFillBagFill />
                          &nbsp; {item?.productNameEn} - {item?.productNameKh} 
                        </TextPro>
                        <p>reviews - {item?.reviews?.count} view</p>     
                      </>                   
                  ))}

              </Item>

          </Grid> */}
{/* 
          <Grid item xs={12}>
            <Item>
              <Title sx={{ fontSize: fTitle }}>New Product</Title>
              
              {topProduct.map(( item ) => (
                      <>                
                        <TextPro sx={{ fontSize: fText }}  className="KhmerOsSiemreap">
                          <BsFillBagFill />
                          &nbsp; {item?.productNameEn} - {item?.productNameKh} 
                        </TextPro>
                        <p>reviews - {item?.reviews?.count} view</p>     
                      </>                   
              ))}

            </Item>
          </Grid> */}


        

      <Grid item container spacing={4} xs={6}>
        {/* <Grid item xs={12}>
          <Item>
            <Box
      
              sx={{ width: "100%", justifyContent: "center", display: "flex" }}
            >           

                <div id="chart" >
                    <ReactApexChart                         
                        options={state.options} 
                        series={state.series} 
                        type="radialBar" 
                        // height={390} 
                    /> 
                </div>
                  
                  
            </Box>
          </Item>
        </Grid> */}
        <Grid item xs={12}>
          {/* <Item>
            <Box
              sx={{ width: "100%", justifyContent: "center", display: "flex" }}
            >
                <div id="chart" >
                    <ReactApexChart                         
                        options={state_district.options} 
                        series={state_district.series} 
                        type="radialBar" 
                        // height={390} 
                    /> 
                </div>


            </Box>
          </Item> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
