import {
  Divider,
  Grid,
  Typography,
  Card,
  Modal,
  CardMedia,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CareerForm from "./CareerForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import api from "../../api/posts";
import { Link } from "react-router-dom";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  overflowY: 'auto',
};

export default function DatailCareer() {
  const { id } = useParams();
  const [editData, setEditData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpened = () => setOpened(true);
  const handleClosed = () => setOpened(false);

  useEffect(async () => {
    await api.get(`/careers/get-career/${id}`).then((res) => {
      console.log(res);
      setEditData(res?.data);
    });
  }, []);

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Link
              to="/career"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Typography variant="h4">Career</Typography>
            </Link>
            <Typography variant="h4">/</Typography>
            <Typography variant="h4">Datail</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    border: "0px outset #fff",
                    borderRadius: 2,
                    align: "center",
                    height: "350px",
                    width: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="350px"
                    width="350px"
                    image="/mario-av.png"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1}>
                <Typography variant="h6">Job Requirement</Typography>
                <Box
                  sx={{
                    border: "1px dashed purple",
                    borderRadius: 2,
                    height: "250px",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    Cheerful african young woman wearing rainbow cardgan
                    laughing at camera. Studio portrait white background.
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ height: 400, backgroundColor: "" }}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      border: "0px outset #fff",
                      borderRadius: 2,
                      height: "250px",
                      width: "100%",
                    }}
                  >
                    <Grid sx={{ display: "flex" }}>
                      <Typography variant="h6" sx={{ p: 2 }}>
                        Sale Outdoor
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontFamily: "Bayon", p: 1, mt: 1 }}
                      >
                        | អ្នកលក់ខាងក្រៅ
                      </Typography>
                    </Grid>
                    <Typography variant="h6" sx={{ fontFamily: "Bayon", p: 1 }}>
                      ដើម្បីក្លាយជាផ្សារដែលផ្តល់ភាពងាយស្រួល
                      និងឈានមុខគេក្នុងការផ្គត់
                      ផ្គង់ផលិតផលក្នុងស្រុកនៅក្នុងប្រទេសកម្ពុជា។
                    </Typography>
                    <Typography sx={{ width: "100%", p: 1 }}>
                      Cheerful african young woman wearing rainbow cardgan
                      laughing at camera. Studio portrait white background.
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      border: "1px dashed purple",
                      borderRadius: 2,
                      height: "250px",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      នារីវ័យក្មេងជនជាតិអាហ្រិករីករាយពាក់ឥន្ទធនូ Cardigan
                      សើចដាក់ការមេរ៉ា។ ស្ទូឌីយោបញ្ឍរត្រូវបានបើក
                      ផ្ទៃខាងក្រោយពណ៌ស។
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      backgroundImage: `linear-gradient(to top, #4838eb, #5334e4, #5c31de, #642dd7, #6a29d1)`,
                      fontSize: 14,
                    }}
                    onClick={handleOpen}
                  >
                    edit job discription
                    <BorderColorIcon sx={{ color: "#fff", fontSize: 15 }} />
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <CareerForm
                      title={"UPDATE CARREER"}
                      controllButton={"Update"}
                      editData={editData}
                    />
                  </Modal>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
