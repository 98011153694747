import * as React from "react";
import { Card, Modal, Stack, Grid} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import api from "../../api/posts";
import { Link } from "react-router-dom";
import UpdateForm from "./UpdateForm";
import AlertMessage from "./AlertMessage";
import CardDelete from "./CardDelete";
import pic from "../../image/default_image.png"
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";


export default function CardOffer({ item , setLoading , setAlert , setMessage , setCheckMessage }) {

  const [opened, setOpened] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpened = () => setOpened(true);
  const handleClosed = () => setOpened(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{maxWidth: 450 , boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>

      {
        item?.image != "" ? 
            <>
              <CardMedia
                component="img"
                height="180"
                image={`${process.env.React_App_UPLOAD_URL}${item?.image} `}
                alt="green iguana"
              />
            </>
          :
            <>
              <CardMedia
                component="img"
                height="180"
                image={pic}
                alt="green iguana"
              />
            </>
      }
      
      

      <CardContent>
        <Grid container spacing={1}>
        <Grid item xs={8}>
          <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontFamily: "Bayon" }}
        >
          {item?.offerNameKh} | 
        </Typography>
        </Grid>
        <Grid item xs={4}>

          <Stack direction="row" spacing={-2}>
                <Button
                  onClick={handleOpened}
                  size="small"
                >
                  <BorderColorIcon/>
                </Button>
                <Button
                  onClick={handleOpen}
                  size="small"
                  sx={{ color: 'red'}}
                >
                  <DeleteOutlineRoundedIcon />
                </Button>
          </Stack>
        
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
          <CardDelete 
              item={item} 
              setAlert={setAlert}
              handleClosed={handleClosed}
              setMessage={setMessage}
              setCheckMessage={setCheckMessage} 
              setLoading={setLoading}
          />
          </Modal>
         
          
          <Modal
            open={opened}
            onClose={handleClosed}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <UpdateForm
              setLoading={setLoading}
              editData={item}
              id={item?._id}
              setAlert={setAlert}
              handleClosed={handleClosed}
              setMessage={setMessage}
              setCheckMessage={setCheckMessage}
            />
          </Modal>
        </Grid>
        <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontFamily: "Bayon" }}
        >
          {item?.offerNameEn}
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontFamily: "Bayon" }}
        >
          {item?.offerTypeKh} | {item?.offerTypeEn}
        </Typography>
        </Grid>
      </Grid>
        
        
        
        
      </CardContent>

          
    </Card>
  );
}
