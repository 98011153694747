import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import DrawerLeft from "./DrawerLeft";

export default function DashboardLayout() {
  return (
    <Box sx={{ margin: 0, display: "flex" }}>
      <Grid sx={{ width: { xs: "0%", xl: "16%" } }}>
        <DrawerLeft />
      </Grid>
      <Grid sx={{ width: { xs: "100%", xl: "84%" } }}>
        <Grid item container>
          <Grid item xs={12} sx={{ p: 4 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
