import * as React from 'react';
import {Box , Typography , Grid , Button} from "@mui/material"
import api from "../../api/posts";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    maxHeight: '100%',
    overflowY: 'auto',
  };


export default function ModalDelete({title, controlButton , getId , modalCheck , setLoading , handleCloseDelete , setAlert , setMessageAlert}) {

    const handledelete = async () => {
        console.log(getId)

        // delete category
        if(modalCheck === "delete-category"){
            await api.delete(`categories/delete-category/${getId}`).then(res => {
                
                handleCloseDelete()
                setLoading(true)
                console.log(res?.data?.message)
                setMessageAlert(res?.data?.message);
                setAlert(true)

            });
        }

        // delete product
        if(modalCheck === "delete-product"){
            await api.delete(`/products/api/delete-product/${getId}`).then(res => {
                
                setLoading(true)
                console.log(res?.data?.message)
                setMessageAlert(res?.data?.message);
                setAlert(true)
            });
        }
    }
    return (
        <>
            <Box sx={style}>
                <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                                <Typography                                         
                                    variant="h5"
                                    sx={{ textAlign:"center" , color:"#5B5BF6" , fontWeight:"bold"}}
                                >
                                        {title}
                                </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                                <Typography                                         
                                    variant="h6"
                                    sx={{ textAlign:"center" }}
                                >
                                        Do you want to delete?
                                </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{display:"flex" ,justifyContent: "center"}}>
                                <Button
                                        size="large"
                                        variant="contained"                                        
                                        sx={{ 
                                            width: "20%" ,
                                            backgroundColor:"#5B5BF6",
                                            "&:hover" : {
                                                backgroundColor:"#5B5BF6",
                                            } 
                                        }}
                                        onClick={handledelete}
                                    >
                                        {controlButton}
                                </Button>
                        </Grid>
                </Grid>
                
            </Box>
        </>
    )
}