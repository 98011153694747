import React from 'react';
import { Typography, Box, Grid, Card,  Divider , styled ,Button  } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import api from "../api/posts";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import pic from "../image/default_image.png"
import Modal from '@mui/material/Modal';
import ModalPost from '../components/Post/ModalPost';
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import AlertMessagePost from '../components/Post/AlertMessagePost';
import { BsFillBagFill } from "react-icons/bs";
import TruncateMarkup from "react-truncate-markup";
import Prism from "prismjs";


const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    textAlign: "center",
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.white,
  }));



export default function PublicProduct() {

    const [openPublish, setOpenPublish] = React.useState(false);
    const handleOpenPublish = () => setOpenPublish(true);
    const handleClosePublish = () => setOpenPublish(false);

    const [openUnpublish, setOpenUnpublish] = React.useState(false);
    const handleOpenUnpublish = () => setOpenUnpublish(true);
    const handleCloseUnpublish = () => setOpenUnpublish(false);


    const [product,setProduct] = React.useState([]);
    const [post,setPost] = React.useState([]);
    const [draft,setDraft] = React.useState([]);

    const [loading,setLoading] = React.useState(true);

      //Set Alert
    const [alert, setAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");

    
    React.useEffect( async () => {

        //get product
        await api.get('/products').then( res => {
            // console.log(res?.data)
            setProduct(res?.data);
        })

        //get post
        await api.get('/products/get-published-post').then( res => {
            // console.log(res, "post")
            setPost(res?.data)
        })  

         //get draft
         await api.get('/products/get-draft-post').then( res => {
            // console.log(res, "post")
            setDraft(res?.data)
        })  
        
        setLoading(false);

    },[loading])



    if(loading){
        return(
            <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            <Grid container spacing={5}>
                
                <Grid item xs={12}>
                        <Stack direction="row" spacing={2} >
                            <Link to="/products" style={{ textDecoration: "none" , color:'black'}}>
                                <Typography variant='h4' sx={{ color: "#472CC9" }}>
                                    Product
                                </Typography>
                            </Link>
                            <Typography variant='h4'>
                                /
                            </Typography>
                            <Typography variant='h4' sx={{ color: "#472CC9" }}>
                                Public Product
                            </Typography>
                        </Stack>
                </Grid>
                
                <Grid item xs={12}>
                    <Divider />
                </Grid>
               
                <Grid item xs={12}>
                    <Grid container spacing={5}>

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} >                            
                                <Typography variant='h5' sx={{ color: "#472CC9" }}>
                                    <BsFillBagFill /> &nbsp;Product Public
                                </Typography>
                            </Stack>
                        </Grid>
                                     
                        {post.map(( item ) => (
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <Card sx={{ height:"420px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"}}>
                                       
                                        { item?.image != "" ? 
                                            <>
                                                <CardMedia
                                                component="img"
                                                height="250"
                                                alt="green iguana"
                                                image={`${process.env.React_App_UPLOAD_URL}${item?.image} `}                     
                                                />

                                            </>
                                            :
                                            <>
                                                <CardMedia
                                                    component="img"
                                                    height="250"
                                                    alt="green iguana"
                                                    image={pic}                
                                                /> 
                                            </>                   
                                        }

                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" className="KhmerOsSiemreap">                                                
                                                    <div className="eval">
                                                            <TruncateMarkup lines={1}>
                                                                <div> { item?.productNameKh } | { item?.productNameEn } </div>
                                                            </TruncateMarkup>
                                                    </div>  
                                            </Typography>
                                            
                                            <Typography variant="body2" color="text.secondary" className="KhmerOsSiemreap"> 

                                                <div className="block">
                                                        <div className="eval">
                                                                <TruncateMarkup lines={2}>
                                                                    <div>{item?.description}</div>
                                                                </TruncateMarkup>
                                                        </div>                                                       
                                                </div>
                                                
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button 
                                                    onClick={handleOpenPublish}
                                                    size="small" 
                                                    variant='outlined' 
                                                    sx={{
                                                        color: "green" , 
                                                        fontWeight: "bold",
                                                        "&:hover": {
                                                            backgroundColor: "#5c31de",
                                                            opacity: [0.3, 0.5, 0.9],
                                                            color: "white",
                                                        },
                                                    }}
                                            >
                                                Published
                                            </Button>

                                            <Modal
                                                open={openPublish}
                                                onClose={handleClosePublish}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <ModalPost 
                                                        titles={"unpublish"}  
                                                        item={item} 
                                                        status={false} 
                                                        setLoading={setLoading} 
                                                        handleClosePublish={handleClosePublish}
                                                        setMessage={setMessage}
                                                        setAlert={setAlert}
                                                />
                                            </Modal>

                                            <Button size="small" >{moment(item?.publishedAt).format("YYYY-MMMM-DD")}</Button>

                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>
                        ))}
                      
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={5}>

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} >                            
                                <Typography variant='h5' sx={{ color: "#472CC9" }}>
                                    <BsFillBagFill /> &nbsp;Product Draft
                                </Typography>
                            </Stack>
                        </Grid>
                                     
                        {draft.map(( item ) => (
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <Card sx={{ height:"420px" , boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"}}>
                                       
                                        { item?.image != "" ? 
                                            <>
                                                <CardMedia
                                                component="img"
                                                height="250"
                                                alt="green iguana"
                                                image={`${process.env.React_App_UPLOAD_URL}${item?.image} `}                     
                                                />

                                            </>
                                            :
                                            <>
                                                <CardMedia
                                                    component="img"
                                                    height="250"
                                                    alt="green iguana"
                                                    image={pic}                
                                                /> 
                                            </>                   
                                        }


                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" className="KhmerOsSiemreap">
                                                    <div className="eval">
                                                            <TruncateMarkup lines={1}>
                                                                <div> { item?.productNameKh } | { item?.productNameEn } </div>
                                                            </TruncateMarkup>
                                                    </div> 
                                               
                                            </Typography>
                                            
                                            <Typography variant="body2" color="text.secondary" className="KhmerOsSiemreap">
                                                    <div className="eval">
                                                            <TruncateMarkup lines={2}>
                                                                <div>{item?.description}</div>
                                                            </TruncateMarkup>
                                                    </div> 
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button 
                                                    onClick={handleOpenUnpublish}
                                                    size="small" 
                                                    variant='outlined' 
                                                    sx={{
                                                        color: "orange" , 
                                                        fontWeight: "bold",
                                                        "&:hover": {
                                                            backgroundColor: "#5c31de",
                                                            opacity: [0.3, 0.5, 0.9],
                                                            color: "white",
                                                        },
                                                    }}
                                            >
                                                Draft
                                            </Button>
                                            
                                            <Modal
                                                open={openUnpublish}
                                                onClose={handleCloseUnpublish}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <ModalPost 
                                                        titles={"publish now"}  
                                                        item={item} 
                                                        status={true} 
                                                        setLoading={setLoading} 
                                                        handleCloseUnpublish={handleCloseUnpublish}
                                                        setMessage={setMessage}
                                                        setAlert={setAlert}
                                                />

                                            </Modal>

                                            
                                            <Button size="small">{moment(item?.publishedAt).format("YYYY-MMMM-DD")}</Button>

                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>
                        ))}
                      
                    </Grid>
                </Grid>

                <AlertMessagePost alert={alert} messageAlert={message} setAlert={setAlert}/>

                
            </Grid>
        </Box >
    )
}
