import React from "react";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import {ThemeProvider} from "@mui/material"
import { makeStyles } from "@mui/styles";
import api from "../../api/posts";
import imageCompression from "browser-image-compression";
import axios from "axios";
import pic from "../../image/default_image.png" 
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { getToken } from "../function/fn";

const CssTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#5B5BF6",
    },
    "& label.Mui-focused": {
      color: "#5B5BF6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5B5BF6",
      },
      "&:hover fieldset": {
        borderColor: "#5B5BF6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B5BF6",
      },
    },
  },
})(TextField);

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  maxHeight: '100%',
  overflowY: 'auto',

};

const Input = styled("input")({
  display: "none",
});

// Style Selct
const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "#5B5BF6",
    },
    "&:after": {
      borderColor: "#5B5BF6",
    },
  },
  icon: {
    fill: "#5B5BF6",
  },
});

const themes = createTheme({
  palette: {
    primary: {
      main: "#5B5BF6",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#5B5BF6",
          },
        },
      },
    },
  },
});
// End Style Select

// upload image
const uploadImage = async (file, dataProduct) => {
  const formData = new FormData();
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  var newFile = new File([compressedFile], `${dataProduct?._id}.png`, {
    type: "image/png",
  });
  formData.append("image", newFile);

  return await axios
    .post(`${process.env.React_App_UPLOAD_URL}/cms-mart-Image/upload`, formData, config)
    .then(async function (response) {
      console.log( "image ",response?.data );
      // /sms/upload/image.png
      const newProduct = {
        ...dataProduct,
        image: response?.data,
      };

      await api
        .put(`/products/api/update-product/${dataProduct?._id}`, newProduct , {
          headers: {
            "authorization" :`Bearer ${getToken()}`
          }
        })
        .then((res) => {
          console.log(res?.data?.message);
        });
    });
};

export default function ModalProduct({
  setAlert,
  setMessageAlert,
  title,
  controlButton,
  editData,
  checkPro,
  setLoading,
  handleCloseProduct,
  handleCloseUpdateProduct,
}) {
  const classes = useStyles();

 
  

  // Upload Image
  const [imageFile, setImageFile] = React.useState(null);
 
  const CategorySchema = Yup.object().shape({
    category: Yup.string().required("Category Name is required!"),
    productNameKh: Yup.string().required("Khmer Name is required!"),
    productNameEn: Yup.string().required("English Name is required!"),
    productType: Yup.string().required("Product Type is required!"),
    unit: Yup.number(),
    price: Yup.number().required("Price is required!"),
    description: Yup.string(),
    suppliers: Yup.string(),
    offers: Yup.string(),
    isPublished: Yup.string(),
    publishedAt: Yup.date(),
  });

  const formik = useFormik({
    initialValues: {
      ...editData,
    },
    validationSchema: CategorySchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {

      //Create Product
      if (checkPro === true) {
        const newProduct = {
          ...values,
          image: "",
        };

        console.log(newProduct);


        await api
          .post("/products/api/create-product", newProduct)
          .then((res) => {
            if (imageFile) {
              uploadImage(imageFile, res?.data?.createdProduct);
            }
            handleCloseProduct();
            setLoading(true);
            setAlert(true);
            console.log(res?.data?.message);
            setMessageAlert(res?.data?.message);
            

          })          
          .catch((err) => {
            console.error(err.response.data.message);
            setMessageAlert(err.response.data.message);
            setAlert(true);
          });
      }

      //Update Product
      if (checkPro === false) {
        await api
          .put(`/products/api/update-product/${editData?._id}`, values)
          .then((res) => {
            if (imageFile) {
              uploadImage(imageFile, res?.data?.updateProduct);
            }

            console.log(res?.data?.message);
            handleCloseUpdateProduct();
            setAlert(true);
            setMessageAlert(res?.data?.message);            
            setLoading(true);           
            
          })
          .catch((err) => {
            console.error(err.response.data.message);
            setMessageAlert(err.response.data.message);
            setAlert(true);
          });
      }
    },
  });

  const handleChange = (newValue) => {
    setFieldValue("publishedAt", newValue);
  };


  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm, } = formik;

  //GET DATA
  const [category, setCategory] = React.useState([]);
  const [supply, setSupply] = React.useState([]);
  const [offer, setOffer] = React.useState([]);

  React.useEffect(() => {
    // Category
    api.get("/categories/get-categories").then((res) => {
      setCategory(res?.data);
    });
    // Supply
    api.get("/suppliers/").then((res) => {
      setSupply(res?.data);
    });
    // Offer
    api.get("/offer/").then((res) => {
      setOffer(res?.data);
    });
  }, []);

  React.useEffect(() => {
    if (imageFile) {
      // console.log(imageFile, "tests");
      console.log(URL.createObjectURL(imageFile, "jje"));
    }
  }, [imageFile]);

  
  return (
    <>
      <Box sx={styleModal}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    color: "#5B5BF6",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    border: "1px dashed #5B5BF6",
                    borderRadius: 2,
                    height: "250px",
                    display: "flex",
                    justifyContent: "center",
                    mt:"10px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                  
                  {/* Update Product */}
                  {checkPro === false ?
                    <>
                        { imageFile ?
                            <>
                                {                     
                                  editData.image === ""  ? 
                                      <>
                                       <Stack 
                                            sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                                        >     
                                            <TextField
                                              sx={{ display: "none" }}
                                              fullWidth
                                              type="file"
                                              id="image"
                                              onChange={(e) => setImageFile(e.target.files[0])}
                                            />   
                                            <Button>
                                              <label for="image">
                                                  <img
                                                    src={URL.createObjectURL(imageFile)} 
                                                    style={{ width: "36vh", height: "25vh" }}
                                                    alt="preview"
                                                  />                           
                                              </label>
                                            </Button>
                                        </Stack>
                                      </>
                                  :
                                      <> 
                                        <Stack 
                                          sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                                          >     
                                              <TextField
                                                sx={{ display: "none" }}
                                                fullWidth
                                                type="file"
                                                id="image"
                                                onChange={(e) => setImageFile(e.target.files[0])}
                                              />   
                                              <Button>
                                                <label for="image">
                                                    <img
                                                      src={URL.createObjectURL(imageFile)} 
                                                      style={{ width: "36vh", height: "25vh" }}
                                                      alt="preview"
                                                    />                           
                                                </label>
                                              </Button>
                                          </Stack>
                                      </>
                                }

                            </>

                          : 

                            <>
                                {                     
                                    editData.image != ""  ? 
                                        <>
                                            <Stack  sx={{  alignItems: "center",   }}  >     
                                                <TextField
                                                  sx={{ display: "none" }}
                                                  fullWidth
                                                  type="file"
                                                  id="image"
                                                  onChange={(e) => setImageFile(e.target.files[0])}
                                                />   
                                                <Button>
                                                  <label for="image">   
                                                      <img
                                                        src={`${process.env.React_App_UPLOAD_URL}${editData?.image}`}
                                                        style={{ width: "36vh", height: "25vh" }}
                                                        alt="preview"
                                                      />                           
                                                  </label>
                                                </Button>
                                            </Stack>
                                        </>
                                      :
                                        <>
                                        
                                          <Stack sx={{ alignItems: "center" }}>
                                              <TextField
                                                sx={{ display: "none" }}
                                                fullWidth
                                                type="file"
                                                id="image"
                                                onChange={(e) => setImageFile(e.target.files[0])}
                                              />
                                              <label for="image">
                                                    <img
                                                      src={pic}
                                                      style={{ width: "36vh", height: "25vh" }}
                                                      alt="preview"
                                                    /> 
                                              </label>                                
                                            </Stack>
                                        </>
                                    }
                            </>
                        }
                    </> 
                    : 
                    <></>
                  }
                  {/* End Update Product */}

                  {/* Create Product */}
                    {checkPro === true ?                     
                        <>
                          { imageFile ? (
                            <>
                            <Stack 
                                sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                            >     
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />   
                                <Button>
                                  <label for="image">
                                      <img
                                        src={URL.createObjectURL(imageFile)} 
                                        style={{ width: "36vh", height: "25vh" }}
                                        alt="preview"
                                      />                           
                                  </label>
                                </Button>
                            </Stack>
                            </>
                          ) : (
                            <>                            
                              <Stack sx={{ alignItems: "center" }}>
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />
                                <label for="image">
                                  <FileUploadOutlinedIcon
                                    sx={{
                                      color: "#5B5BF6",
                                      width: "40px",
                                      height: "40px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    color: "#5B5BF6",
                                  }}
                                >
                                  Add Profile Image
                                </Typography>
                              </Stack>
                            </>
                          )}

                      </> 
                    :
                      <></>
                  }

                  {/* End Create Product */}
                    
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <ThemeProvider theme={themes}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                          select: classes.select,
                        },
                      }}
                      label="Category"
                      {...getFieldProps("category")}
                      error={Boolean(touched.category && errors.category)}
                    >
                      {category.map((cat) => (
                        <MenuItem value={`${cat._id}`}>
                          {cat.categoryNameEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  fullWidth
                  label="Khmer Name"
                  {...getFieldProps("productNameKh")}
                  error={Boolean(touched.productNameKh && errors.productNameKh)}
                  helperText={touched.productNameKh && errors.productNameKh}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  fullWidth
                  label="English Name"
                  {...getFieldProps("productNameEn")}
                  error={Boolean(touched.productNameEn && errors.productNameEn)}
                  helperText={touched.productNameEn && errors.productNameEn}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CssTextField
                  multiline
                  fullWidth
                  label="Description"
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />               
              </Grid>
              <Grid item xs={6} md={6}>
                <CssTextField
                  fullWidth
                  label="Price"
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CssTextField
                  fullWidth
                  label="Unit"
                  {...getFieldProps("unit")}
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <ThemeProvider theme={themes}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Product Type</InputLabel>
                    <Select
                      className={classes.select}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      label="Product Type"
                      {...getFieldProps("productType")}
                      error={Boolean(touched.productType && errors.productType)}
                    >
                      <MenuItem value="NewProduct">New Product</MenuItem>
                      <MenuItem value="MainProduct">Main Product</MenuItem>
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>
              <Grid item xs={6} md={6}>
                <ThemeProvider theme={themes}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Offers</InputLabel>
                    <Select
                      className={classes.select}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      label="Offers"
                      {...getFieldProps("offers")}
                      error={Boolean(touched.offers && errors.offers)}
                    >
                      {offer.map((off) => (
                        <MenuItem value={`${off._id}`}>
                          {off.offerNameEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>
              <Grid item xs={12} md={12}>
                <ThemeProvider theme={themes}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Suppliers</InputLabel>
                    <Select
                      className={classes.select}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      label="Suppliers"
                      {...getFieldProps("suppliers")}
                      error={Boolean(touched.suppliers && errors.suppliers)}
                    >
                      {supply.map((sup) => (
                        <MenuItem value={`${sup._id}`}>
                          {sup.companyNameEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>

              <Grid item xs={6} >
                <ThemeProvider theme={themes}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      className={classes.select}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      label="Status"
                      {...getFieldProps("isPublished")}
                      error={Boolean(touched.isPublished && errors.isPublished)}
                    >                      
                        <MenuItem value="true">Publish</MenuItem>
                        <MenuItem value="false">Unpublish</MenuItem>                    
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Grid>

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Set Time"
                      value={values.publishedAt}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField {...params} type="date" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
              </Grid>


              <Grid item xs={12} md={12}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "#5B5BF6",
                    "&:hover": {
                      backgroundColor: "#5B5BF6",
                    },
                  }}
                >
                  {controlButton}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
}
