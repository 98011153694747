import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { CardContent } from "@mui/material"
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
//components
import CareerForm from "../components/Career/CareerForm";
import CardCareer from "../components/Career/CardCereer";
import api from "../api/posts";
import ChildComponent from "../ChildComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const useStyles = makeStyles(() => ({
  overrides: {
    MuiFormControl: {
      root: {
        height: "56px",
      },
    },
    MuiInputBase: {
      root: {
        height: "56px",
      },
    },
  },
}));

const data = [
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
];

export default function Career() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [career, setCareer] = useState([]);

  //   console.log(career);

  useEffect(async () => {
    await api.get("/careers/get-career").then((res) => {
      console.log(res?.data);
      setCareer(res?.data);
    });
  }, []);

  return (
    <div>
      <ChildComponent title={"Career"} />
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h4" sx={{ color: "#472CC9" }}>Career</Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Button variant="outlined" onClick={handleOpen}>
                New career +
              </Button>
              <Modal
                // open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <CareerForm
                  handleClose={handleClose}
                  title={"CREATE CAREER"}
                  controllButton={"ADD"}
                  checkCareer={true}
                />
              </Modal>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
                <Box
                      sx={{
                          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                          borderRadius: 2,
                          align: "center",
                          height: "400px",
                          width: "100%",
                          color: "#4838eb",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                  >
                      <CardContent>

                          <Grid container spacing={1} sx={{display:"flex", flexDirection: "column" , justifyContent:"center"}}>

                              <Grid item xs={12}>
                                  <Typography gutterBottom variant="h1" component="div" sx={{ fontFamily: "Bayon" }} >
                                          Coming Soon !
                                  </Typography>
                              </Grid>                               
                              
                          </Grid>                                  
                                                      
                      </CardContent>
                  </Box>
          </Grid>

          {/* {career.map((job) => (
            <Grid item xs={6} md={4} lg={3}>
              <Link
                to={`/career/detail/${job?._id}`}
                style={{ textDecoration: "none" }}
              >
                <CardCareer />
              </Link>
            </Grid>
          ))} */}


        </Grid>
      </Box>
    </div>
  );
}
