import React from 'react';
import { Typography, Box, Grid, Card, Divider, styled, Button, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Carousel from "react-elastic-carousel";
import MainProductItem from '../components/Product/MainProductItem';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import ModalCategory from '../components/Product/ModalCategory';
import ModalProduct from '../components/Product/ModalProduct';
import HeadCategory from '../components/Product/HeadCategory';
import api from '../api/posts';
import CircularProgress from "@mui/material/CircularProgress";
import AlertMessage from "../components/Product/AlertMessage"
import { getToken } from '../components/function/fn';


const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    textAlign: "center",
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.white,
}));



export default function MainProduct() {

    const [editData,setEditData] = React.useState(null);
    const [openCategory, setOpenCategory] = React.useState(false);
    const handleOpenCategory = () => setOpenCategory(true);
    const handleCloseCategory = () => setOpenCategory(false);

    const [loading,setLoading] = React.useState(true)

    const [openProduct, setOpenProduct] = React.useState(false);
    const handleOpenProduct = () => setOpenProduct(true);
    const handleCloseProduct = () => setOpenProduct(false);

    // Alert Message
    const [alert,setAlert] = React.useState(false);
    const [messageAlert,setMessageAlert] = React.useState("");


    //GET DATA
    const [category, setCategory] = React.useState([]);
  let navigate = useNavigate();
    
    const fetchData = async ()=>{
        await api.get('/categories/get-categories',).then(res => {
            setCategory(res?.data)
        })
        .catch(err=>{
            // console.log(err.response.data.message)
            if(err.response.data.message === "jwtexpired"){
                setMessageAlert("Account Expired!")
                setAlert(true)   
                setTimeout( () => {
                    window.localStorage.removeItem("accessToken")
                    navigate("/") 
                },2000)             
                              
            }
        })        
        setLoading(false)
    }


    React.useEffect(async() => {
        if(loading){
            // category
            await fetchData()
        }
      }, [loading])

    

    if (loading) {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <CircularProgress />
          </Box>
        );
    }

    return (
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={6} md={6}>                   
                    <Stack direction="row" spacing={2}>
                            <Link to="/products" style={{ textDecoration: "none" , color:'black'}}>
                                <Typography variant='h4' sx={{ color: "#472CC9" }}>
                                    Product
                                </Typography>
                            </Link>
                            {/* <Typography variant='h4'>
                                /
                            </Typography>
                            <Typography variant='h4' sx={{ color: "#472CC9" }}>
                                Main Product
                            </Typography>                             */}
                    </Stack>
                </Grid>
                <Grid item xs={5} md={5} sx={{ display: "flex", justifyContent: "right" }}>
                    <Button variant='outlined' size='small' height="20px" onClick={handleOpenCategory}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant='p'>
                                CATEGORY
                            </Typography>
                            <AddIcon sx={{fontSize:"20px"}}/>
                        </Stack>
                    </Button>

                    <Modal
                        open={openCategory}
                        onClose={handleCloseCategory}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <ModalCategory 
                            setAlert={setAlert}
                            setMessageAlert={setMessageAlert}
                            title={"ADD CATEGORY"} 
                            controlButton={"CREATE"} 
                            editData={{ 
                                categoryNameKh: "", 
                                categoryNameEn: "", 
                                note: ""
                            }}
                            checkCat={true}
                            setLoading={setLoading}
                            handleCloseCategory={handleCloseCategory}
                        />
                    </Modal>


                </Grid>
                <Grid item xs={12} md={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={12}>

                    <Grid container spacing={2}>
                        <Grid item xs={10} md={10}>
                            <Grid container spacing={2}>

                            {category.map((cat) => (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <HeadCategory 
                                                setLoading={setLoading} 
                                                cat={cat} 
                                                setAlert={setAlert} 
                                                setMessageAlert={setMessageAlert}
                                        /> 
                                    </Grid>
                                    <Grid item xs={12} md={12}>   
                                        <Box sx={{w:"100%"}}>                                    
                                            <MainProductItem categoryId={cat._id} setAlert={setAlert} setMessageAlert={setMessageAlert}/>
                                        </Box>                                            
                                    </Grid>                                
                                </>
                            ))}

                            </Grid>

                        </Grid>

                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "left" }} height="fit-content">

                            <Button variant='outlined' size='small'  onClick={handleOpenProduct}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant='p'>
                                            PRODUCT
                                    </Typography>
                                    <AddIcon sx={{fontSize:"20px"}}/>
                                </Stack>
                            </Button>

                            {/* <Button variant='outlined' size='small' height="20px" onClick={handleOpenProduct} >
                                <Stack direction="row" spacing={1}>
                                    <Typography variant='p'>
                                        PRODUCT
                                    </Typography>
                                    <AddIcon />
                                </Stack>
                            </Button> */}

                            <Modal
                                open={openProduct}
                                onClose={handleCloseProduct}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <ModalProduct 
                                    title={"ADD PRODUCT"} 
                                    controlButton={"ADD"} 
                                    editData={{ 
                                        category: "",
                                        productNameKh: "",
                                        productNameEn: "",
                                        productType: "",
                                        unit: "",
                                        price: 0,
                                        description: "",
                                        suppliers: "",
                                        offers: "",
                                        isPublished:"",
                                        publishedAt : new Date(),
                                    }}
                                    setAlert={setAlert} 
                                    setMessageAlert={setMessageAlert}
                                    checkPro={true}
                                    setLoading={setLoading}
                                    handleCloseProduct={handleCloseProduct}
                                />
                            </Modal>                                                                

                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

            <AlertMessage alert={alert} messageAlert={messageAlert} setAlert={setAlert}/>
            
        </Box >
    )
}
