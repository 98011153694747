import * as React from "react";
import {  Box, Stack, Button, Grid, Typography } from "@mui/material";
import api from "../../api/posts";
import AlertMessage from "./AlertMessage";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};


export default function CardDelete({item, handleClosed, setMessage, setCheckMessage, setAlert , setLoading }) {

  const DeleteOffer = async () => {  
    await api.delete(`/offer/api/delete-offer/${item._id}`).then((res) => {
        handleClosed();
        setLoading(true);
        setMessage(res?.data?.message);
        //console.log(res?.data?.color);
        setCheckMessage("delete");
        setAlert(true);
    });
  };

  return (
    
    <Box sx={styleModal}>
        <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                        <Typography                                         
                                variant="h5"
                                sx={{ textAlign:"center" , color:"#5B5BF6" , fontWeight:"bold"}}
                        >
                                DELETE OFFER
                        </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                        <Typography                                         
                                variant="h6"
                                sx={{ textAlign:"center" }}
                        >
                                Do you want to delete?
                        </Typography>
                </Grid>
                <Grid item xs={12} md={12} sx={{display:"flex" ,justifyContent: "center"}}>
                        <Button
                                size="large"
                                variant="contained"                                        
                                sx={{ 
                                        width: "20%" ,
                                        backgroundColor:"#5B5BF6",
                                        "&:hover" : {
                                        backgroundColor:"#5B5BF6",
                                        } 
                                }}
                                onClick={DeleteOffer}
                                >
                                OKAY
                        </Button>
                </Grid>
        </Grid>
    
</Box>
  );
}
