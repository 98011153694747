import * as React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  Divider,
  styled,
  Button,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { Link , useNavigate } from "react-router-dom";
import ChildComponent from "../ChildComponent";
import pictuer from "../image/Shopping_Bag_Paper_Mockup.jpg";
import api from "../api/posts";
import AlertMessage from "../components/Product/AlertMessage";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.white,
  borderRadius: 20,
}));

export default function Products() {
  let navigate = useNavigate();
  //Set Alert
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] =React.useState("");
  const [totalProduct,setTotalProduct] = React.useState(null);
  const [post,setPost] = React.useState(null);
  const [draft,setDraft] = React.useState(null);

  React.useEffect( async () => {

      await api.get('/products').then( res => {
        console.log(res?.data.length , "getProduct")
        setTotalProduct(res?.data.length)
      })      

      //get post
      await api.get('/products/get-published-post').then( res => {
        console.log(res, "post")
        setPost(res?.data?.length)
      }) 

      //get post
      await api.get('/products/get-draft-post').then( res => {
        // console.log(res, "post")
        setDraft(res?.data.length)
      }) 
      .catch(err=>{
        // console.log(err.response.data.message)
        if(err.response.data.message === "jwtexpired"){
          setMessage("Account Expired!")
            setAlert(true)   
            setTimeout( () => {
                window.localStorage.removeItem("accessToken")
                navigate("/") 
            },2000)             
                          
        }
      })

  },[])

  return (
    <div>
      <ChildComponent title={"Products"} />
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={6} md={6}>
            <Typography variant="h4" sx={{ color: "#472CC9" }}>Product</Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} sx={{ marginTop: "40px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12} sm={12} md={6} lg={6}              
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box width="80%">
                  <RootStyle
                    sx={{
                      "&:hover": {
                        backgroundColor: "#5c31de",
                        opacity: [0.3, 0.5, 0.9],
                      },
                    }}
                  >
                    <Link
                      to="/products/mainproduct"
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        container
                        spacing={10}
                        sx={{
                          pl: "50px",
                          pr: "50px",
                          color: "#4838eb", 
                          "&:hover": {
                            backgroundColor: "#5c31de",
                            opacity: [0.3, 0.5, 0.9],
                            color: "white",
                          },
                        }}
                      >
                        <Grid item xs={6}  sx={{ flexDirection: "column", display:"flex" , justifyContent:"center"}}>
                          <Avatar
                            sx={{ width: 200, height: 200 }}
                            alt="Main Product"
                            src={`${pictuer}`}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{ flexDirection: "column", display:"flex" , justifyContent:"center"}}>
                          <Box
                            sx={{
                              width: 300,
                              height: 300,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h4" sx={{ textAlign: "left" }}>
                              Product
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "left" }}>
                              Total: {totalProduct} unit
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Link>
                  </RootStyle>
                </Box>
              </Grid>
              <Grid
                item
                xs={12} sm={12} md={6} lg={6}                
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box width="80%">
                  <RootStyle
                    sx={{
                      "&:hover": {
                        backgroundColor: "#5c31de",
                        opacity: [0.3, 0.5, 0.9],
                      },
                    }}
                  >
                    <Link
                      to="/products/public"
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        container
                        spacing={10}
                        sx={{
                          pl: "50px",
                          pr: "50px",
                          color: "#4838eb",
                          "&:hover": {
                            backgroundColor: "#5c31de",
                            opacity: [0.3, 0.5, 0.9],
                            color: "white",
                          },
                          display:"flex",
                          justifyContent: "center"
                        }}
                      >
                        <Grid item xs={6} sx={{ flexDirection: "column", display:"flex" , justifyContent:"center"}}>
                          <Avatar
                             sx={{ width: 200, height: 200 }}
                            alt="Remy Sharp"
                            src={`${pictuer}`}
                          />
                        </Grid>

                        <Grid item xs={6} sx={{ flexDirection: "column", display:"flex" , justifyContent:"center"}}>
                          <Box
                            sx={{
                              width: 300,
                              height: 300,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h4" sx={{ textAlign: "left" }}>
                              Publish
                            </Typography>
                           
                            <Typography variant="h6" sx={{ textAlign: "left" }}>
                              Total: {post+draft} unit
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Link>
                  </RootStyle>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

     <AlertMessage messageAlert={message} alert={alert} setAlert={setAlert}/>
    </div>
  );
}
