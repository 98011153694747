import * as React from "react"
import { Typography , Box  , Grid , Button } from "@mui/material";
import api from "../../api/posts"
 
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };


export default function ModalPost({ status , titles , item ,setLoading , handleClosePublish , handleCloseUnpublish , setMessage , setAlert }) {

    const handleStatus = async (e) => {       

        if(e === false ) {

            const values = {
                "isPublished" : e ,
            };
            await api.put(`/products/api-update-status/${item?._id}`, values).then((res) => {           
                console.log(res?.data?.message);  
                handleClosePublish();
                setLoading(true);     
                setMessage("Unpublish Success!");
                setAlert(true);
              });

        } else {

            const today = new Date();        
            const date = today;                  
            const newDate = {
                "publishedAt" : date ,
            };
            await api.put(`/products/api-update-draftpublish/${item?._id}` , newDate ).then(res => {
                console.log(res?.data?.message)
                handleCloseUnpublish()
                setLoading(true) 
                setMessage("Publish Success!")
                setAlert(true)
            })

        }
        
    }
      
    return (
        <>
                           
            <Box sx={style}>
                    <Grid item container spacing={2}>

                        <Grid item xs={12} >
                                <Typography                                         
                                    variant="h6"
                                    sx={{ textAlign:"center" }}
                                >
                                        Do you want to {titles}?
                                </Typography>
                        </Grid>
                        
                        <Grid item xs={12} sx={{display:"flex" ,justifyContent: "center"}}>
                                <Button
                                        size="large"
                                        variant="contained"                                        
                                        sx={{ 
                                            width: "20%" ,
                                            backgroundColor:"#5B5BF6",
                                            "&:hover" : {
                                                backgroundColor:"#5B5BF6",
                                            } 
                                        }}
                                        onClick={() => { handleStatus(status) }}
                                    >
                                        OK
                                </Button>
                        </Grid>

                    
                    </Grid>
            </Box>
               
        </>
    )
}