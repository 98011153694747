import React from "react";
import Stack from "@mui/material/Stack";
import { Typography, TextField, Box, Grid, Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { withStyles } from "@material-ui/styles";
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import api from "../../api/posts"
import axios from "axios";
import imageCompression from "browser-image-compression";
import pic from "../../image/default_image.png" 


const CssTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#5B5BF6",
    },
    "& label.Mui-focused": {
      color: "#5B5BF6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5B5BF6",
      },
      "&:hover fieldset": {
        borderColor: "#5B5BF6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B5BF6",
      },
    },
  },
})(TextField);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};


// upload image
const uploadImage = async (file, dataSupply) => {
  const formData = new FormData();
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  var newFile = new File([compressedFile], `${dataSupply?._id}.png`, {
    type: "image/png",
  });
  formData.append("image", newFile);

  return await axios
    .post(`${process.env.React_App_UPLOAD_URL}/cms/upload`, formData, config)
    .then(async function (response) {
      console.log(response?.data);
      // /sms/upload/image.png
      const newSupply = {
        ...dataSupply,
        image: response?.data,
      };

      await api
        .put(`suppliers/api/update-supplier/${dataSupply?._id}`, newSupply)
        .then((res) => {
          console.log(res?.data?.message);
        });
    });
};



export default function AddSupply({ title, controlButton, editData, checkSupplier, setAlert, setMessage, handleClose , setLoading }) {

  // Upload Image
  const [imageFile, setImageFile] = React.useState(null);

  
const SupplySchema = Yup.object().shape({
    email: Yup.string().required("email is required!"),
    companyNameKh: Yup.string().required("Company name khmer is required!"),
    companyNameEn: Yup.string().required("Company name english is required!"),
    addressEn: Yup.string().required("Address English is required!"),
    addressKh: Yup.string().required("Address Khmer is required!"),
    tel: Yup.number().required("Phone is required!"),

});

const formik = useFormik({
    initialValues: {
        //  image
        //  companyNameKh: "",
        //  companyNameEn: "",
        //  addressEn: "",
        //  addressKh: "",
        //  email:"",
        //  tel:"",
        ...editData,
         
    },
    validationSchema: SupplySchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {

        // console.log(values);

        //create supplier
        if(checkSupplier === false ) {

          const newSupply = {
            ...values,
            image: "",
          };

          api.post('suppliers/api/create-supplier', newSupply).then(res => {
                       
            if (imageFile) {
              // console.log(res?.data?.createdsupplier?._id)
              uploadImage(imageFile, (res?.data?.createdsupplier) )
            }            
            handleClose();
            setLoading(true)            
            // console.log(res?.data?.message)
            setMessage(res?.data?.message);
            setAlert(true);
              
          }) 
       }
       
        //update supplier
        if(checkSupplier === true ){          

          await api.put(`suppliers/api/update-supplier/${editData._id}`,values).then(res =>{
            
            if (imageFile) {
              uploadImage(imageFile, (res?.data?.updateSupplier) )
            }

            handleClose(); 
            setLoading(true)           
            // console.log(res?.data)
            setMessage(res?.data?.message);
            setAlert(true);
          })
        }
    },
});

const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

 

  return (
    <Box sx={style}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  color: "#5B5BF6",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  border: "1px dashed #5B5BF6",
                  borderRadius: 2,
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >


                    {/* Update Supply */}
                  {checkSupplier === true ?
                    <>
                        { imageFile ?
                            <>
                                {                     
                                  editData.image === ""  ? 
                                      <>
                                       <Stack 
                                            sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                                        >     
                                            <TextField
                                              sx={{ display: "none" }}
                                              fullWidth
                                              type="file"
                                              id="image"
                                              onChange={(e) => setImageFile(e.target.files[0])}
                                            />   
                                            <Button>
                                              <label for="image">
                                                  <img
                                                    src={URL.createObjectURL(imageFile)} 
                                                    style={{ width: "36vh", height: "25vh" }}
                                                    alt="preview"
                                                  />                           
                                              </label>
                                            </Button>
                                        </Stack>
                                      </>
                                  :
                                      <> 
                                        <Stack 
                                          sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                                          >     
                                              <TextField
                                                sx={{ display: "none" }}
                                                fullWidth
                                                type="file"
                                                id="image"
                                                onChange={(e) => setImageFile(e.target.files[0])}
                                              />   
                                              <Button>
                                                <label for="image">
                                                    <img
                                                      src={URL.createObjectURL(imageFile)} 
                                                      style={{ width: "36vh", height: "25vh" }}
                                                      alt="preview"
                                                    />                           
                                                </label>
                                              </Button>
                                          </Stack>
                                      </>
                                }

                            </>

                          : 

                            <>
                                {                     
                                    editData.image != ""  ? 
                                        <>
                                            <Stack  sx={{  alignItems: "center",   }}  >     
                                                <TextField
                                                  sx={{ display: "none" }}
                                                  fullWidth
                                                  type="file"
                                                  id="image"
                                                  onChange={(e) => setImageFile(e.target.files[0])}
                                                />   
                                                <Button>
                                                  <label for="image">   
                                                      <img
                                                        src={`${process.env.React_App_UPLOAD_URL}${editData?.image}`}
                                                        style={{ width: "36vh", height: "25vh" }}
                                                        alt="preview"
                                                      />                           
                                                  </label>
                                                </Button>
                                            </Stack>
                                        </>
                                      :
                                        <>
                                        
                                          <Stack sx={{ alignItems: "center" }}>
                                              <TextField
                                                sx={{ display: "none" }}
                                                fullWidth
                                                type="file"
                                                id="image"
                                                onChange={(e) => setImageFile(e.target.files[0])}
                                              />
                                              <label for="image">
                                                    <img
                                                      src={pic}
                                                      style={{ width: "36vh", height: "25vh" }}
                                                      alt="preview"
                                                    /> 
                                              </label>                                
                                            </Stack>
                                        </>
                                    }
                            </>
                        }
                    </> 
                    : 
                    <></>
                  }
                  {/* End Update Product */}

                  {/* Create Product */}
                    {checkSupplier === false ?                     
                        <>
                          { imageFile ? (
                            <>
                            <Stack 
                                sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                            >     
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />   
                                <Button>
                                  <label for="image">
                                      <img
                                        src={URL.createObjectURL(imageFile)} 
                                        style={{ width: "36vh", height: "25vh" }}
                                        alt="preview"
                                      />                           
                                  </label>
                                </Button>
                            </Stack>
                            </>
                          ) : (
                            <>                            
                              <Stack sx={{ alignItems: "center" }}>
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />
                                <label for="image">
                                  <FileUploadOutlinedIcon
                                    sx={{
                                      color: "#5B5BF6",
                                      width: "40px",
                                      height: "40px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    color: "#5B5BF6",
                                  }}
                                >
                                  Add Profile Image
                                </Typography>
                              </Stack>
                            </>
                          )}

                      </> 
                    :
                      <></>
                  }

                  {/* End Create Supply */}


                </Box>

              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Company NameKh"
                {...getFieldProps("companyNameKh")}
                error={Boolean(touched.companyNameKh && errors.companyNameKh)}
                helperText={touched.companyNameKh && errors.companyNameKh}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Company NameEn"
                {...getFieldProps("companyNameEn")}
                error={Boolean(touched.companyNameEn && errors.companyNameEn)}
                helperText={touched.companyNameEn && errors.companyNameEn}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Address En"
                {...getFieldProps("addressEn")}
                error={Boolean(touched.addressEn && errors.addressEn)}
                helperText={touched.addressEn && errors.addressEn}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Address Kh"
                {...getFieldProps("addressKh")}
                error={Boolean(touched.addressKh && errors.addressKh)}
                helperText={touched.addressKh && errors.addressKh}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Phone"
                {...getFieldProps("tel")}
                error={Boolean(touched.tel && errors.tel)}
                helperText={touched.tel && errors.tel}
              />
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{ p: 2, backgroundColor: "#4838eb" }}
                fullWidth
              >
                {controlButton}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
