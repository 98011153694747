import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { Divider, Card, styled, CardContent } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PlaceIcon from "@mui/icons-material/Place";
import ChildComponent from "../ChildComponent";

const FounderRes = { xs: 160, sm: 180, md: 180, lg: 250, xl: 250 };

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  // textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.white,
  borderRadius: 20,
}));

export default function Career() {
  return (
    <div>
      <ChildComponent title={"Company"} />
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={6} md={6}>
            <Typography variant="h4" sx={{ color: "#472CC9" }}>Company Info</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box width="90%">
                  <RootStyle
                    sx={{
                      "&:hover": {
                        backgroundColor: "#5c31de",
                        opacity: [0.3, 0.5, 0.9],
                      },
                    }}
                  >
                    <Link
                      // to="/company/detailCom"
                      to=""
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        container
                        sx={{
                          pl: "50px",
                          pr: "50px",
                          color: "#4838eb",
                          "&:hover": {
                            backgroundColor: "#5c31de",
                            opacity: [0.3, 0.5, 0.9],
                            color: "white",
                          },
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 200,
                              height: 200,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "left",
                            }}
                            alt="Remy Sharp"
                            src="https://www.wallpapertip.com/wmimgs/141-1418814_bhojpuri-actress-hd-wallpaper-photo-image-sanchita-banerjee.jpg"
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Box
                            sx={{
                              height: FounderRes,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "left",
                            }}
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{ fontFamily: "Bayon" }}
                              >
                                អ្នកស្រី ចាន់ ន
                              </Typography>
                              <Typography gutterBottom variant="body2">
                                Alexander Channo
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body2"
                                sx={{ fontFamily: "Bayon" }}
                              >
                                Co Founder - អ្នកគ្រប់គ្រងទូទៅ
                              </Typography>
                            </CardContent>
                          </Box>
                        </Grid>
                      </Grid>
                    </Link>
                  </RootStyle>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box width="90%">
                  <RootStyle
                    sx={{
                      "&:hover": {
                        backgroundColor: "#5c31de",
                        opacity: [0.3, 0.5, 0.9],
                      },
                    }}
                  >
                    <Link
                      // to="/company/detailInfo"
                      to=""
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        sx={{
                          pl: "50px",
                          pr: "50px",
                          color: "#4838eb",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "#5c31de",
                            opacity: [0.3, 0.5, 0.9],
                            color: "white",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: 250,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                          }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ fontFamily: "Bayon" }}
                            >
                              ប្រភពល្អ អាហារប្រសើ ជីវិតប្រពៃ
                            </Typography>
                            <Typography variant="body2">
                              Good farm, better food, best life
                            </Typography>
                            <Typography variant="body2">
                              <PhoneInTalkIcon /> 087 9988 802 | 092 889 009
                            </Typography>
                            <Typography variant="body2">
                              <AlternateEmailIcon /> info@goglobal-mart.com
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "Bayon" }}
                            >
                              <PlaceIcon />{" "}
                              នារីវ័យក្មេងជនជាតិអាហ្រិករីករាយពាក់ឥន្ទធនូ ។
                            </Typography>
                            <Typography variant="body2">
                              <PlaceIcon /> Aafrican young woman wearing rainbow
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    </Link>
                  </RootStyle>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
