import React from "react";
import { Modal } from "@mui/material";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ModalDelete from "./ModalDelete";
import ModalProduct from "./ModalProduct";
import TruncateMarkup from "react-truncate-markup";
import { Box } from "@mui/material"

export default function HeadProduct({ item, setLoading , setAlert , setMessageAlert }) {
  const [openUpdateProduct, setOpenUpdateProduct] = React.useState(false);
  const handleOpenUpdateProduct = () => setOpenUpdateProduct(true);
  const handleCloseUpdateProduct = () => setOpenUpdateProduct(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  return (
    <>
      <Grid container spacing={0} sx={{ mt: "5px" }}>
        <Grid item xs={8} display="flex" justifyContent="left">
          <Typography
            variant="h6"
            className="KhmerOsSiemreap"
            sx={{ color: "black", ml: "15px" }}
          >
            <TruncateMarkup lines={1}>
                <div>{item?.productNameKh} | {item?.productNameEn}</div>
            </TruncateMarkup>
            
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={-2}>
            <Button size="small" onClick={handleOpenUpdateProduct}>
              <ModeEditOutlineOutlinedIcon />
            </Button>
            <Button size="small" onClick={handleOpenDelete}>
              <DeleteOutlineRoundedIcon sx={{ color: "red" }} />
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mt: "5px"}}>  

            <Box sx={{ w:"100%" , ml:"15px" , mr:"15px"}}>
                <Typography
                    variant="p"
                    className="KhmerOsSiemreap"
                    sx={{ color: "black" }}
                  >
                    <TruncateMarkup lines={1}>
                        <div> {item?.description} </div>
                    </TruncateMarkup>
                    
                </Typography>   
            </Box>                    
            
        </Grid>
        

        {/* <Stack direction="row" spacing={2} sx={{ p:"15px" , mt: "10px" , justifyContent:"left"}} > */}

        {/* <Typography sx={{ justifyContent:"right"}}>                                      */}

        <Modal
          open={openUpdateProduct}
          onClose={handleCloseUpdateProduct}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalProduct
            setAlert={setAlert}
            setMessageAlert= {setMessageAlert}
            title={"UPDATE PRODUCT"}
            controlButton={"UPDATE"}
            editData={item}
            checkPro={false}
            setLoading={setLoading}
            handleCloseUpdateProduct={handleCloseUpdateProduct}
          />
        </Modal>

        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalDelete
            setAlert={setAlert}
            setMessageAlert= {setMessageAlert}
            setLoading={setLoading}
            title={"DELETE PRODUCT"}
            controlButton={"Delete"}
            getId={item?._id}
            modalCheck={"delete-product"}
          />
        </Modal>

        {/* </Typography> */}

        {/* </Stack>   */}
      </Grid>
    </>
  );
}
