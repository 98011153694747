import * as React from 'react';
import {Box , Typography , Grid , Button} from "@mui/material"
import api from '../../api/posts'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5
  };


export default function ModalDeleteSupplier({ getId , handleCloseDelete , setLoading  , setAlert , setMessage }) {

    const handledelete = async () => {
        console.log(getId)

            await api.delete(`suppliers/api/delete-supplier/${getId}`).then(res => {
                handleCloseDelete();                
                setLoading(true)                
                console.log(res?.data?.message)
                setMessage(res?.data?.message)
                setAlert(true);
               
            });
    }

    return (
        <>
            <Box sx={style}>
                <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                                <Typography                                         
                                    variant="h5"
                                    sx={{ textAlign:"center" , color:"#5B5BF6" , fontWeight:"bold"}}
                                >
                                        DELETE SUPPLIER
                                </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                                <Typography                                         
                                    variant="h6"
                                    sx={{ textAlign:"center" }}
                                >
                                        Do you want to delete?
                                </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{display:"flex" ,justifyContent: "center"}}>
                                <Button
                                        size="large"
                                        variant="contained"                                        
                                        sx={{ 
                                            width: "20%" ,
                                            backgroundColor:"#5B5BF6",
                                            "&:hover" : {
                                                backgroundColor:"#5B5BF6",
                                            } 
                                        }}
                                        onClick={handledelete}
                                    >
                                        OKAY
                                </Button>
                        </Grid>
                </Grid>
                
            </Box>
        </>
    )
}