import * as React from "react";
import { Card, Grid, Button, Modal , Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PlaceIcon from "@mui/icons-material/Place";
import Box from "@mui/material/Box";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ModalDeleteSupplier from "./ModalDeleteSupplier";
import pic from "../../image/default_image.png"
import CircularProgress from "@mui/material/CircularProgress";
import AddSupply from "./AddSupply";


export default function CardSupplier({company , setAlert , setMessage , getId , setLoading }) {

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [image,setImage] = React.useState();

  React.useEffect( () => {

      if(company?.image != "" ) {
          setImage(`${process.env.React_App_UPLOAD_URL}${company?.image} `)
      } else {       
          setImage(`${pic}`)
      }

  }, [company])
 
   


  return (
      <Card sx={{ maxWidth: 450 ,boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="180"
          image={image}
        />
        <CardContent>
          <Grid container>
              <Grid xs={11} sx={{ display: "inline-flex", flexDirection: 'column', mb:1 }}>                
                  <Typography variant="h5" component="div" fontFamily="Khmer Os Siemreap">
                      {company.companyNameKh} |
                  </Typography>                
                  <Typography variant="h5" component="div" fontFamily="Century">
                      {company.companyNameEn} 
                  </Typography>              
              </Grid>
              <Grid xs={1} sx={{ justifyContent: 'flex-end', display: "inline-flex" }}>

                <Stack direction="row" spacing={-2}>
                    <Button onClick={handleOpen}>
                        <BorderColorIcon sx={{ color: "#1976d2"}} />
                    </Button>
                    <Button onClick={handleOpenDelete}>
                        <DeleteOutlineRoundedIcon sx={{ color: "red" }} />
                    </Button>
                </Stack>
                
                  <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                  >
                      <AddSupply 
                          title={'EDIT SUPPLIER'} 
                          controlButton={'UPDATE'} 
                          editData={company} 
                          checkSupplier={true} 
                          handleClose={handleClose} 
                          setAlert={setAlert} 
                          setMessage={setMessage}
                          setLoading= {setLoading}
                      />
                  </Modal>  
                <Modal
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ModalDeleteSupplier 
                      getId={getId} 
                      handleCloseDelete={handleCloseDelete} 
                      setLoading={setLoading} 
                      setAlert={setAlert}
                      setMessage={setMessage}
                  />
                </Modal>

              </Grid>

              <Grid item xs={12}>
                <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      bgcolor: "background.paper",
                      borderRadius: 1,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sx={{ display: 'flex' }}>
                        <PhoneInTalkIcon sx={{  color: "#1976d2" }}/>&nbsp;
                          <Typography>
                            {company.tel}
                          </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex' }}>
                        <AlternateEmailIcon sx={{color: "#1976d2"}} />&nbsp;    
                          <Typography>
                          {company.email} 
                          </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex' }}>
                        <PlaceIcon sx={{color: "#1976d2" }} />&nbsp;
                          <Typography>
                            {company.addressKh} / {company.addressEn}
                          </Typography>
                      </Grid>
                    </Grid> 
                </Box>
              </Grid>
          </Grid>
    </CardContent>
  </Card>
  );
}
