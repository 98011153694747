import axios from 'axios';
import React from 'react';
import TokenContext from '../components/context/tokenContext';
import { getToken } from '../components/function/fn';


export default axios.create({
    baseURL: 'https://api.cms.goglobalmart.com/',
    // baseURL: 'http://192.168.2.205:5200/',
    headers:{ 
        "authorization" :`Bearer ${getToken()}`, 
    },  
        
})

// export  =()=>{

//     const token = React.useContext(TokenContext)

//     return axios.create({
//         // baseURL: 'https://api.cms.goglobalmart.com/',
//         baseURL: 'http://192.168.2.205:5200/',
//         headers:{ 
//             "authorization" :`Bearer ${token}`, 
//         },  
//     })
// }

