import React from "react";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import api from "../../api/posts";

const CssTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#5B5BF6",
    },
    "& label.Mui-focused": {
      color: "#5B5BF6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5B5BF6",
      },
      "&:hover fieldset": {
        borderColor: "#5B5BF6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B5BF6",
      },
    },
  },
})(TextField);

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  maxHeight: '100%',
  overflowY: 'auto',
};

export default function ModalCategory({
  setAlert,
  setMessageAlert,
  title,
  controlButton,
  editData,
  checkCat,
  setLoading,
  handleCloseCategory,
  handleCloseUpdateCategory,
}) {
  const CategorySchema = Yup.object().shape({
    categoryNameKh: Yup.string().required("Khmer Name is required!"),
    categoryNameEn: Yup.string().required("English Name is required!"),
    note: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      ...editData,
      // categoryNameKh: "",
      // categoryNameEn: "",
      // note: "",
    },
    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // console.log(values);

      // create
      if (checkCat === true) {
        await api.post("/categories/create-category", values).then((res) => {          
          handleCloseCategory();
          setLoading(true);          
          console.log(res?.data?.message);
          setMessageAlert(res?.data?.message);
          setAlert(true);
        });
      }
      //update
      if (checkCat === false) {
        await api
          .put(`/categories/update-category/${editData?._id}`, values)
          .then((res) => {
            
            handleCloseUpdateCategory();
            setLoading(true);            
            console.log(res?.data?.message);
            setMessageAlert(res?.data?.message);
            setAlert(true);
          });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <Box sx={styleModal}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    color: "#5B5BF6",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <CssTextField
                  fullWidth
                  label="Khmer Name"
                  {...getFieldProps("categoryNameKh")}
                  error={Boolean(
                    touched.categoryNameKh && errors.categoryNameKh
                  )}
                  helperText={touched.categoryNameKh && errors.categoryNameKh}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CssTextField
                  fullWidth
                  label="English Name"
                  {...getFieldProps("categoryNameEn")}
                  error={Boolean(
                    touched.categoryNameEn && errors.categoryNameEn
                  )}
                  helperText={touched.categoryNameEn && errors.categoryNameEn}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CssTextField
                  fullWidth
                  label="Note"
                  {...getFieldProps("note")}
                  error={Boolean(touched.note && errors.note)}
                  helperText={touched.note && errors.note}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "#5B5BF6",
                    "&:hover": {
                      backgroundColor: "#5B5BF6",
                    },
                  }}
                >
                  {controlButton}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>

      
    </>
  );
}
