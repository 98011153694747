import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChildComponent from "../ChildComponent";


const StyleBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
}));

export default function Page404() {

  return (
    <StyleBox>
      <ChildComponent title={"#404"}/>
      <Box marginTop={"300px"}>
        <Typography variant="h2" component="div">
          Sorry the page your are looking for is not found!
        </Typography>
        <Typography variant="h1" component="div">
            <Link style={{ textDecoration: "none" }} to="/">          
              <Button 
                  size="large"
                  variant="outlined"
              >
                  <h2>GO TO HOME PAGE</h2>
              </Button>         
            </Link>
        </Typography>
      </Box>
    </StyleBox>
  );
}
