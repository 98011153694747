import { Divider, Grid, Typography, Modal ,CardContent } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import AddFounder from "./AddFounder";
import AddFounderInfo from "./AddFounderInfo";
import { Link } from "react-router-dom";
import pic from "../../image/default_image.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PlaceIcon from "@mui/icons-material/Place";


const Input = styled("input")({
  display: "none",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function DetailInfomation() {
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpened = () => setOpened(true);
  const handleClosed = () => setOpened(false);

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" spacing={2}>
            <Link
              to="/company"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Typography variant="h4">Company Info</Typography>
            </Link>
            <Typography variant="h4">/</Typography>
            <Typography variant="h4">Details Information</Typography>
          </Stack>
          {/* <Typography variant='h4'>Company Info</Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 400, display: "flex" }}>
            <Grid container spacing={10}>

              <Grid item xs={6}>

                <Stack spacing={2}>
                    <Box 
                        sx={{
                            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                            borderRadius: 2,                          
                            height: "200px",
                            width: "100%",
                            // backgroundImage: `url(${pic})`,   
                            backgroundImage: `url("https://mui.com/static/images/cards/contemplative-reptile.jpg")`,
                            backgroundRepeat: "no-repeat",     
                            backgroundSize: "cover",
                            backgroundPosition: "center center",                  
                        }}
                     />

                    <Box
                        sx={{
                            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                            borderRadius: 2,
                            align: "center",
                            height: "400px",
                            width: "100%",
                            color: "#4838eb",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CardContent>

                            <Grid container spacing={1} sx={{display:"flex", flexDirection: "column" , justifyContent:"center"}}>

                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h4" component="div" sx={{ fontFamily: "Bayon" }} >
                                    ប្រភពល្អ អាហារប្រសើ ជីវិតប្រពៃ
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Good farm, better food, best life
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <PhoneInTalkIcon /> 087 9988 802 | 092 889 009
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <AlternateEmailIcon /> info@goglobal-mart.com
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ fontFamily: "Bayon" }} > 
                                        <PlaceIcon />                                       
                                        នារីវ័យក្មេងជនជាតិអាហ្រិករីករាយពាក់ឥន្ទធនូ ។
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <PlaceIcon /> Aafrican young woman wearing rainbow
                                    </Typography>
                                </Grid>
                                
                            </Grid>                                  
                                                        
                        </CardContent>
                    </Box>

                    <Button
                        onClick={handleOpen}
                        variant="contained"
                        sx={{
                        width: "100%",
                        borderRadius: 3,
                        mt: 5,
                        backgroundImage: `linear-gradient(to top, #4838eb, #5334e4, #5c31de, #642dd7, #6a29d1)`,
                        }}
                    >
                        Edit
                        <IconButton
                        sx={{ color: "#fff" }}
                        aria-label="upload picture"
                        component="span"
                        >
                        <BorderColorIcon />
                        </IconButton>
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                        <AddFounderInfo />
                        </Box>
                    </Modal>

                </Stack>
              </Grid>


              <Grid item xs={6}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      border: "1px dashed purple",
                      borderRadius: 2,
                      height: "250px",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      Cheerful african young woman wearing rainbow cardigan
                      laughing atcamera. Studio portrait on white background.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: "1px dashed purple",
                      borderRadius: 2,
                      height: "250px",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ p: 2, fontFamily: "Bayon" }}>
                      នារីវ័យក្មេងជនជាតិអាហ្រិករីករាយពាក់ឥន្ទធនូ​ cardigan
                      សើចដាក់កាមេរ៉ា។ ស្ទូឌីយោបញ្ឈរត្រូវបានបើក ផ្ទៃខាងក្រោយពណ៌ស។
                    </Typography>
                  </Box>

                  <Button
                    onClick={handleOpened}
                    variant="contained"
                    sx={{
                      width: "100%",
                      borderRadius: 3,
                      mt: 5,
                      backgroundImage: `linear-gradient(to top, #4838eb, #5334e4, #5c31de, #642dd7, #6a29d1)`,
                    }}
                  >
                    {" "}
                    Edit
                    <IconButton
                      sx={{ color: "#fff" }}
                      aria-label="upload picture"
                      component="span"
                    >
                      <BorderColorIcon />
                    </IconButton>
                  </Button>
                  <Modal
                    open={opened}
                    onClose={handleClosed}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <AddFounder />
                  </Modal>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
