import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  ArgumentAxis,
  Chart,
  BarSeries,
  ValueAxis,
  Title,
} from "@devexpress/dx-react-chart-material-ui";
import api from "../../api/posts";
import { Link , useNavigate} from "react-router-dom";
import AlertMessageSupplier from "../Supplier/AlertMessageSupplier";
// /visitors/get-visitor-by-country
// /visitors/get-visitor
// /visitors/get-visitor-bymoth

const ChartBar = () => {
  //Set Alert
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  let navigate = useNavigate();

  const [city, setCity] = useState(null);
  const [weather,setWeather] = useState();

  // Query DATA
  const [visitorByDay, setVisitorByDay] = useState(null);
  const [data, setData] = useState([]);
  function visitor( argument ,  value) {

    return {argument, value}
  }


  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const [address, setAddress] = useState("");

  var geolocation = require("geolocation");

  const getLocation = () => {
    geolocation.getCurrentPosition(function (err, position) {
      setLocation({
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      });

      reverseCoordinate({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      });
    });
  };

  const reverseCoordinate = async ({ lat, lng }) => {
    await fetch(
      `https://us1.locationiq.com/v1/reverse.php?key=pk.065688a786ec31fb4ef4df9c364c3138&lat=${lat}&lon=${lng}&format=json`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data?.address)
        setCity(data?.address?.city || data?.address?.state);
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect( async () => {


    
    // await api.get("/visitors/get-visitor-by-country").then((res) => {
    //   console.log(res?.data);
    //   // setCareer(res?.data);
    // });


    // await api.get("/visitors/get-visitor").then((res) => {
    //   console.log(res?.data);
    // });


    // await api.get("/visitors/get-visitor-bymoth").then((res) => {
    //   console.log(res?.data);
    // });

    let rows = [];
    await api.get("/visitors/get-visitorby-day")
    .then((res) => {
        console.log(res?.data, "get-visitorby-day");
        res?.data.forEach(
          element => {
              let allRow = visitor(
                  element._id,
                  element.totalVisitor,
              );
              rows.push(allRow);
              setData([...rows]);
          }
      );        
    })
    .catch(err=>{
      // console.log(err.response.data.message)
      if(err.response.data.message === "jwtexpired"){
         setMessage("Account Expired!")
          setAlert(true)   
          setTimeout( () => {
              window.localStorage.removeItem("accessToken")
              navigate("/") 
          },2000)             
                        
      }
    })
     
  }, []);


  // const data = [ { argument: "1", value: 23 } ];
  
  return (
    <>
    <Chart 
        height={320} 
        data={data}
        // rotated={true}
    >
      <ArgumentAxis />
      <ValueAxis />
      <BarSeries
        valueField="value"
        argumentField="argument"
        color="#4838eb"
        type="bar"
      />
    </Chart>
  <AlertMessageSupplier alert={alert} message={message} setAlert={setAlert}/>
  </>
  );
};

export default ChartBar;
