import React from "react";
import Stack from "@mui/material/Stack";
import { Typography, TextField, Box, Grid, Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { withStyles } from "@material-ui/styles";
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useFormik, Form, FormikProvider } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import api from "../../api/posts";
import AlertMessage from "./AlertMessage";
import imageCompression from "browser-image-compression";
import axios from "axios";



const Input = styled("input")({
  display: "none",
});

const CssTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#5B5BF6",
    },
    "& label.Mui-focused": {
      color: "#5B5BF6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5B5BF6",
      },
      "&:hover fieldset": {
        borderColor: "#5B5BF6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B5BF6",
      },
    },
  },
})(TextField);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  overflowY: 'auto',
};



// upload image
const uploadImage = async (file, dataOffer) => {
  const formData = new FormData();
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  var newFile = new File([compressedFile], `${dataOffer?._id}.png`, {
    type: "image/png",
  });
  formData.append("image", newFile);

  return await axios
    .post(`${process.env.React_App_UPLOAD_URL}/cms/upload`, formData, config)
    .then(async function (response) {
      console.log(response?.data);
      // /sms/upload/image.png
      const newSupply = {
        ...dataOffer,
        image: response?.data,
      };

      await api
        .put(`/offer/api/update-offer/${dataOffer?._id}`, newSupply)
        .then((res) => {
          console.log(res?.data?.message);
        });
    });
};




export default function AddForm({setAlert, handleClose, setMessage , setCheckMessage , setLoading } ) {

  // Upload Image
  const [imageFile, setImageFile] = React.useState(null);

const OfferSchema = Yup.object().shape({
    offerTypeKh: Yup.string().required("Type khmer is required!"),
    offerTypeEn: Yup.string().required("Type english is required!"),
    offerNameKh: Yup.string().required("Offer name khmer is required!"),
    offerNameEn: Yup.string().required("Offer name English is required!"),
});

const formik = useFormik({
    initialValues: {
         // image
         offerTypeKh:"",
         offerTypeEn:"",
         offerNameKh:"",
         offerNameEn:"",
         
    },

    validationSchema: OfferSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {

        const newOffer = {
          ...values,
          image: "",
        };

        await api.post('/offer/api/create-offer',newOffer).then(res => {

            if (imageFile) {
              uploadImage(imageFile, (res?.data?.createdOffer) )
            }
            console.log(res?.data);

            handleClose();
            setLoading(true);
            setMessage(res?.data?.message);
            setCheckMessage("create");
            setAlert(true); 
        }) 
       
    },
});

const { errors, touched, values, isSubmitting, checkProp, handleSubmit,getFieldProps, setFieldValue, resetForm } = formik;

  return (
    <Box sx={style}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  color: "#5B5BF6",
                  fontWeight: "bold",
                }}
              >
                ADD Offer
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  border: "1px dashed #5B5BF6",
                  borderRadius: 2,
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",                  
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    mt:"10px"
                  }}
                >
                  {/* Image */}
                      { imageFile ? ( 
                            <>
                            <Stack 
                                sx={{ alignItems: "center",  display: imageFile ? "block" : "none",}} 
                            >     
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />   
                                <Button>
                                  <label for="image">
                                      <img
                                        src={URL.createObjectURL(imageFile)} 
                                        style={{ width: "36vh", height: "25vh" }}
                                        alt="preview"
                                      />                           
                                  </label>
                                </Button>
                            </Stack>
                            </>
                          ) : (
                            <>                            
                              <Stack sx={{ alignItems: "center" }}>
                                <TextField
                                  sx={{ display: "none" }}
                                  fullWidth
                                  type="file"
                                  id="image"
                                  onChange={(e) => setImageFile(e.target.files[0])}
                                />
                                <label for="image">
                                  <FileUploadOutlinedIcon
                                    sx={{
                                      color: "#5B5BF6",
                                      width: "40px",
                                      height: "40px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    color: "#5B5BF6",
                                  }}
                                >
                                  Add Profile Image
                                </Typography>
                              </Stack>
                            </>
                          )}
                    {/* End Image */}

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="OfferTypeKh"
                {...getFieldProps("offerTypeKh")}
                error={Boolean(touched.offerTypeKh && errors.offerTypeKh)}
                helperText={touched.offerTypeKh && errors.offerTypeKh} 
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="OfferTypeEn"
                {...getFieldProps("offerTypeEn")}
                error={Boolean(touched.offerTypeEn && errors.offerTypeEn)}
                helperText={touched.offerTypeEn && errors.offerTypeEn}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Vision Khmer"
                {...getFieldProps("offerNameKh")}
                error={Boolean(touched.offerNameKh && errors.offerNameKh)}
                helperText={touched.offerNameKh && errors.offerNameKh} 
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Vision English"
                {...getFieldProps("offerNameEn")}
                error={Boolean(touched.offerNameEn && errors.offerNameEn)}
                helperText={touched.offerNameEn && errors.offerNameEn}
              />
            </Grid>
            <Grid item xs={12} md={12} mt={2}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "#5B5BF6",
                    "&:hover": {
                      backgroundColor: "#5B5BF6",
                    },
                  }}
              >  
                Add
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
