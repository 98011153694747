import { Button, Grid, Typography, Modal, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link , useNavigate} from "react-router-dom";
import AddSupply from "../components/Supplier/AddSupply";
import makeStyles from "@mui/styles/makeStyles";
import CardSupplier from "../components/Supplier/CardSupplier";
import ChildComponent from "../ChildComponent";
import api from "../api/posts"
import AlertMessageSupplier from "../components/Supplier/AlertMessageSupplier";
import CircularProgress from "@mui/material/CircularProgress";
 

export default function Supplier() {

  //Set Alert
  const [alert, setAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  let navigate = useNavigate();

  // get data 
  const [supplier, setSupplier] = React.useState([]);
  const [limit,setLimit] = React.useState(10);
  const [page,setPage] = React.useState(1)

  //loading
  const [loading,setLoading] = React.useState(true);

  React.useEffect(async () => {

    if(loading){
      await api.get('/suppliers/get-suppliers-withpagination').then( res=> {
          // console.log(res?.data?.suppliers?.docs)
          setSupplier(res?.data?.suppliers?.docs)
        }
      )
      .catch(err=>{
        // console.log(err.response.data.message)
        if(err.response.data.message === "jwtexpired"){
           setMessage("Account Expired!")
            setAlert(true)   
            setTimeout( () => {
                window.localStorage.removeItem("accessToken")
                navigate("/") 
            },2000)             
                          
        }
      })

      setLoading(false);
    }    
  }, [loading])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  if(loading){
    return(
        <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <CircularProgress />
        </Box>
    )
  }

  return (
    <div>
      <ChildComponent title={"Supplier"} />
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Typography variant="h4" sx={{ color: "#472CC9" }} >Supplier</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Button variant="outlined" onClick={handleOpen}>
                SUPPLY+
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <AddSupply 
                      title={"CREATE SUPPLIER"} 
                      controlButton={"SUBMIT"} 
                      checkSupplier={false} 
                      setAlert={setAlert} 
                      setMessage={setMessage} 
                      handleClose={handleClose}
                      setLoading={setLoading}
                />
              </Modal>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {supplier.map(( company ) => (
                <>
                  <Grid
                    item
                    xs={12} sm={12} md={6} lg={6} xl={4}
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <CardSupplier 
                          handleOpen={handleOpen} 
                          company={company} 
                          setAlert={setAlert} 
                          setMessage={setMessage} 
                          getId={company?._id}
                          setLoading={setLoading}
                          setLoadings={true}      
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AlertMessageSupplier alert={alert} message={message} setAlert={setAlert}/>
    </div>
  );
}
