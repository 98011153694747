import React from "react";
import { Typography, Box, Grid, Divider } from "@mui/material";
import ChartBar from "../components/Dashboard/Chart";
import Boxinger from "../components/Dashboard/Boxinger";
import ChildComponent from "../ChildComponent";

export default function Dashboard() {
  return (
    <div>
      <ChildComponent title={"Dashboard"} />
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" sx={{ color: "#472CC9" }}>
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ color: "#472CC9" }}>
              Chart Visitor
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <ChartBar />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Boxinger />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
