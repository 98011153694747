import React from "react"
import Stack from '@mui/material/Stack';
import { Typography , Button } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Modal from '@mui/material/Modal';
import ModalCategory from "./ModalCategory"
import ModalDelete from "./ModalDelete"

export default function HeadCategory({ cat , setLoading , setAlert , setMessageAlert }) {

    const [openUpdateCategory, setOpenUpdateCategory] = React.useState(false);
    const handleOpenUpdateCategory = () => setOpenUpdateCategory(true);
    const handleCloseUpdateCategory = () => setOpenUpdateCategory(false);

    const [openDelete,setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);


    return (
        <>
            <Stack direction="row" spacing={2}>
                <Typography variant='h6' className="KhmerOsSiemreap">
                    {cat?.categoryNameEn}
                </Typography>
                <Typography variant='h6'>
                    |
                </Typography>
                <Typography variant='h6' className="KhmerOsSiemreap">
                    {cat?.categoryNameKh}
                </Typography>

                <Stack direction="row" spacing={-2}>
                    <Typography>
                        
                        <Button size="small" sx={{ margin: 0 }} onClick={handleOpenUpdateCategory}>
                            <ModeEditOutlineOutlinedIcon />
                        </Button>
                        <Modal
                            open={openUpdateCategory}
                            onClose={handleCloseUpdateCategory}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <ModalCategory 
                                    title={"UPDATE CATEGORY"} 
                                    controlButton={"UPDATE"} 
                                    editData={cat} 
                                    checkCat={false} 
                                    setLoading={setLoading}
                                    handleCloseUpdateCategory={handleCloseUpdateCategory}
                                    setMessageAlert={setMessageAlert}
                                    setAlert={setAlert}

                            />
                        </Modal>

                    </Typography>

                    <Typography>
                        
                        <Button size="small" sx={{ ml: "-15" ,}} onClick={handleOpenDelete}>
                            <DeleteOutlineRoundedIcon sx={{color:"red"}}/>
                        </Button>
                        <Modal
                            open={openDelete}
                            onClose={handleCloseDelete}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <ModalDelete 
                                    title={"Delete CATEGORY"} 
                                    controlButton={"Delete"} 
                                    getId={cat?._id} 
                                    modalCheck={"delete-category"} 
                                    setLoading={setLoading}
                                    handleCloseDelete={handleCloseDelete}
                                    setMessageAlert={setMessageAlert}
                                    setAlert={setAlert}
                            />
                        </Modal>

                    </Typography>
                </Stack>

            </Stack>
        </>
    )
}