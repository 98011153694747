import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Paper,
  Box,
  Button,
  Link,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import api from "../api/posts";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlertMessageLogin from "../components/Login/AlertMessageLogin" 
import pic from "../image/LogoWhite.svg";

const colors = "#fff";

export default function Login() {

  // Alert Message
  const [alert,setAlert] = useState(false);
  const [message,setMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");


  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const paperStyle = {
    padding: 30,
    borderRadius: 30,
    backgroundImage: `linear-gradient(to top, #4838eb, #5334e4, #5c31de, #642dd7, #6a29d1)`,
  };

  const UserAdminSchema = Yup.object().shape({
    username: Yup.string(),
    password: Yup.string()
      .min(6, "Password must be more than 6 characters!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: UserAdminSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values, "form values");

      await api.post("/users/api/authenticate", values).then( res  => {
        console.log(res?.data , "when login")
        
        if (res?.data?.accessToken) {
          console.log(res?.data)
          window.localStorage.setItem("accessToken", res?.data?.accessToken);
          setMessage(res?.data?.message);
          setErrorMessage("success");
          setAlert(true);
          setTimeout(() => {
            navigate("/");            
          }, 1000);

        } else {
          window.localStorage.removeItem("accessToken");
          navigate("/");
        }
      })
      .catch( (err) => {
          console.error(err.response.data.message);
          setMessage(err.response.data.message);
          setErrorMessage("error");
          setAlert(true);
      })
      
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const height = 44;
  const labelOffset = -6;
  const focused = 5;
  
  return (
      <>
      <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
             
        <Box
          className="p-0 background-image"
          fluid={true}
          sx={{
            w: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={1}
            display="flex"
            sx={{ justifyContent: "center" }}
          >
            <Grid xs={9} sm={5.5} md={4} lg={2.8} xl={2.3} style={paperStyle}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ justifyContent: "center", display: "flex" }}>
                    <img src={pic} width={"100"} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} align="center">
                  <Typography variant="subtitle2" sx={{ color: colors }}>
                    LOGIN TO
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: colors, mt: 1 }}>
                    GO GLOBAL MART SYSTEM
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: colors }}>Username</Typography>

                  <TextField 
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                    }}                   
                    fullWidth
                    autoComplete
                    // label="Username"
                    {...getFieldProps("username")}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                     
                    style={{ height }}                                          
                    inputProps={{
                        style: {
                          borderRadius:5,
                          height,
                          padding: '0 14px',
                        },
                    }}

                  />

                  
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: colors }}>Password</Typography>

                  <FormControl
                    sx={{ color: "red", width: "100%" }}
                    variant="outlined"
                  >                    
                    <OutlinedInput
                      sx={{
                        backgroundColor: "#fff"
                      }}
                      id="outlined-adornment-password"
                      // label="Password"
                      type={showPassword ? "text" : "password"}
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                            sx={{ color: "#472CC9" }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ height }}                       
                      inputProps={{
                          style: {
                            height,
                            padding: '0 14px',
                          },
                      }}

                    />
                  </FormControl>
                  

                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Link href="#" sx={{ color: colors }}>
                      You foget password?
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      boxShadow:'none',
                      fontWeight: "bold",
                      color: "#4838eb",
                      borderRadius: 3,
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#d6d5ff",
                        boxShadow:'none',
                      },
                      flexGrow: 1,
                    }}
                    fullWidth

                  >
                    <Typography
                      sx={{
                        color: "#4838eb",
                        flexGrow: 1,
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      Log In
                    </Typography>
                    <ArrowForwardIcon fontSize="15px" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
          
        </Box>
        <Grid container spacing={5} 
            sx={{display:"flex", justifyContent: "center" }}>
            <Grid xs={8} md={12} lg={12} display="flex" flexDirection="column" justifyContent="buttom">
                <Typography variant="subtitle2" align="center" color="#4838eb" mt={1}>
                  @COPYRIGHT GO GLOBAL SCHOOL | IT DEPARTMENT
                </Typography>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <AlertMessageLogin  alert={alert} setAlert={setAlert} message={message} errorMessage={errorMessage}/>
    </>
  );
}
