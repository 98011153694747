import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemText, ListItem, IconButton, Grid, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import GridViewIcon from '@mui/icons-material/GridView';
import AddchartIcon from '@mui/icons-material/Addchart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import RadarIcon from '@mui/icons-material/Radar';
import { Link, useLocation } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import Logout from '../../page/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import pic from "../../image/LogoWhite.svg";


const useStyles = makeStyles(theme => ({
    footer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-end',
    }
}));

const spacGrid = { md: 1, lg: 1, xl: 3 }
export default function DrawerLeft({ handleDrawerClose }) {
    const classes = useStyles();
    // action Menu
    const location = useLocation()
    // console.log(location.pathname)

    //Modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <Box
            sx={{
                m: 1,
                backgroundImage: `linear-gradient(to top, #4838eb, #5334e4, #5c31de, #642dd7, #6a29d1)`,
                height: '100%',
                width:"15%",
                borderRadius: 10,               
                padding: 3,
                position:"fixed",
                display: 'flex', 
                flexDirection: 'column' ,
            }}>
            <Grid container spacing={spacGrid}>
                <Grid item xs={12}>
                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                        <IconButton onClick={handleDrawerClose}>
                            <img src={pic} width={100} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2} sx={{ justifyContent: 'center', display: 'flex' }}>
                    <Box sx={{ border: '1px solid #fff', width: '85%' }} />
                </Grid>
                <Grid item xs={12}>
                    <List component="nav" aria-label="main mailbox folders" >
                        <ListItem
                            style={{ color: '#ffff' }}
                        >
                            <ListItemText
                                primary="MENU"
                                primaryTypographyProps={{
                                    fontWeight: 'bold'
                                }} />
                        </ListItem>
                        <Link to='/'  style={{ color: '#ffff', textDecoration: 'none' }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <GridViewIcon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText sx={{ml:'-15px'}} primary="Dashboard" /> 
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/dashboard' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link>
                        {/* <Link to='/company' style={{ color: '#ffff', textDecoration: 'none', }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <AddchartIcon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText primary="Company Info" />
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/company' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link> */}
                        <Link to='/career' style={{ color: '#ffff', textDecoration: 'none' }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <BusinessCenterIcon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText primary="Career" sx={{ml:'-15px'}}/>
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/career' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link>
                        <Link to='products' style={{ color: '#ffff', textDecoration: 'none' }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <ShoppingCartIcon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText primary="Products" sx={{ml:'-15px'}}/>
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/products' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link>
                        <Link to='offer' style={{ color: '#ffff', textDecoration: 'none' }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <PersonAddAlt1Icon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText primary="Offer" sx={{ml:'-15px'}}/>
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/offer' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link>
                        <Link to='supplier' style={{ color: '#ffff', textDecoration: 'none' }}>
                            <ListItemButton
                            >
                                <ListItemIcon>
                                    <ShoppingBagIcon sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText primary="Supplier" sx={{ml:'-15px'}}/>
                                <KeyboardDoubleArrowLeftIcon sx={{ display: location.pathname === '/supplier' ? 'block' : 'none', color: '#fff' }} />
                            </ListItemButton>
                        </Link>
                    </List >                   
                </Grid> 
            </Grid>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Grid container spacing={1}>
                <Grid  item xs={12}
                    sx={{                      
                        display:"flex",
                        flexDirection:"column" ,
                        justifyContent:"center"
                    }}
                >
                    <Box 
                        className={classes.footer}
                        sx={{                            
                            width: "100%",
                        }}
                    >
                        
                        <ListItemButton
                            onClick={handleOpen}
                            sx={{ borderRadius: 10 }}
                        >
                            <ListItemText primary='Logout' primaryTypographyProps={{
                                color: '#fff',
                                variant: 'h6',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }} />
                        </ListItemButton>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Logout />
                        </Modal>
                    </Box>
                </Grid>

                
            </Grid>
             
            
             
             
        </Box>
    );
}