import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage({ alert, setAlert , message, checkMessage }) {

  const [open, setOpen] = React.useState(false); 

  React.useEffect( async () => {

    if(alert){ 
      setOpen(alert);
    }
     await setAlert(false);

  },[alert])
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  // console.log(checkMessage);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {
        checkMessage === "create"||checkMessage === "update" ?
         <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:'top' , horizontal:'center'}}>
                <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
         </> : <></>
      }
      
      {
        checkMessage === "delete" ?
          <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:'top' , horizontal:'center' }}>
                <Alert onClose={handleClose}  severity="warning" sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
          </> : <></>
      }
      

      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
}