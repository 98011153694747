import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState , useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// layouts
import LayoutDashboard from "./layout/LayoutDashboard";
//Page
import Dashboard from "./page/Dashboard";
import Company from "./page/Company";
import Career from "./page/Career";
import Offer from "./page/Offer";
import Products from "./page/Products";
import Supplier from "./page/Supplier";
import Login from "./page/Login";
import LayoutCareer from "./layout/LayoutCareer";
import DatailCareer from "./components/Career/DatailCareer";
import LayoutCompany from "./layout/LayoutCompany";
import DetailCompany from "./components/Company/DetailCompany";
import LayoutOffer from "./layout/LayoutOffer";
import LayoutProduct from "./layout/LayoutProduct";
import MainProduct from "./page/MainProduct";
import PublicProduct from "./page/PublicProduct";
import DetailInfomation from "./components/Company/DetailInfomation"
import Page404 from "./page/Page404"
// import ModalOffer from "./components/Offer/ModalOffer";
// ----------------------------------------------------------------------

export default function Router({setToken}) {
  
  const accessToken = window.localStorage.getItem("accessToken");
  let navigate = useNavigate();
  let pageLogin = useRoutes([{ path: "*", element: <Login /> }]);

  // const {} = useContext()

  let AppPage = useRoutes([
    {
      path: "/",
      element: <LayoutDashboard to="/dashboard" />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        {
          path: "career",
          element: <LayoutCareer to="/career" />,
          children: [
            { path: "", element: <Career /> },
            { path: "detail/:id", element: <DatailCareer /> },
          ],
        },
        {
          path: "company",
          element: <LayoutCompany to="/company" />,
          children: [
            { path: "", element: <Company /> },
            { path: "detailCom", element: <DetailCompany /> },
            { path: "detailInfo", element: <DetailInfomation /> },
          ],
        },

        {
          path: "products",
          element: <LayoutProduct to="/products" />,
          children: [
            { path: "", element: <Products /> },
            { path: "mainproduct", element: <MainProduct /> },
            { path: "public", element: <PublicProduct /> },
          ],
        },
        {
          path: "offer",
          element: <LayoutOffer to="/offer" />,
          children: [
            { path: "", element: <Offer /> },
           
          ],
        },
        { path: "supplier", element: <Supplier /> },

        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },

      ],
    },
  ]);

  // useEffect(() => {
  //   if (isLogout) {
  //     navigate("/");
  //   }
  // }, [isLogout]);
  
  if (accessToken) {
    setToken(accessToken)
    return AppPage;
  } else {
    return pageLogin;
  }
}
