import React from "react";
import Stack from "@mui/material/Stack";
import { Typography, TextField, Box, Grid, Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { withStyles } from "@material-ui/styles";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import api from "../../api/posts";

const Input = styled("input")({
  display: "none",
});

const CssTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#5B5BF6",
    },
    "& label.Mui-focused": {
      color: "#5B5BF6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5B5BF6",
      },
      "&:hover fieldset": {
        borderColor: "#5B5BF6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B5BF6",
      },
    },
  },
})(TextField);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function CareerForm({
  handleClose,
  title,
  controllButton,
  checkCareer,
  editData,
}) {
  const CareerSchema = Yup.object().shape({
    jobTitleKh: Yup.string().required("Title khmer is required!"),
    jobTitleEn: Yup.string().required("Title english is required!"),
    jobDescriptionKh: Yup.string().required("Khmer Discription is required!"),
    jobDescriptionEn: Yup.string().required("English Discription is required!"),
  });

  const formik = useFormik({
    initialValues: {
      // image
      // titleKh: "",
      // jobTitleEn: "",
      // jobDescriptionKh: "",
      // jobDescriptionEn: "",
      ...editData,
    },
    validationSchema: CareerSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      // create
      if (checkCareer === true) {
        await api.post("/careers/api/create-career", values).then((res) => {
          console.log(res?.data?.message);
          handleClose(true);
          // setLoading(true);
        });
      }
      if(checkCareer === false){
        await api.put("/careers/api/update-career/:id", values).then((res) => {
          console.log(res?.data?.message);
          handleClose(true);
          // setLoading(true);
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  // const [supply , setSupply] = React.useState([]);
  // React.useEffect(() => {
  //     api.get('').then(res => {
  //         // console.log(res?.data)
  //         setSupply(res?.data)
  //     });
  //   }, [])

  return (
    <Box sx={style}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  color: "#5B5BF6",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  border: "1px dashed #5B5BF6",
                  borderRadius: 2,
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Stack sx={{ alignItems: "center" }}>
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                      />
                      <IconButton aria-label="upload picture" component="span">
                        <FileUploadOutlinedIcon
                          sx={{ fontSize: 40, color: "#5B5BF6" }}
                        />
                      </IconButton>
                    </label>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 15,
                        color: "#5B5BF6",
                      }}
                    >
                      Add Profile Image
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Khmer Title"
                {...getFieldProps("jobTitleKh")}
                error={Boolean(touched.jobTitleKh && errors.jobTitleKh)}
                helperText={touched.jobTitleKh && errors.jobTitleKh}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="English Title"
                {...getFieldProps("jobTitleEn")}
                error={Boolean(touched.jobTitleEn && errors.jobTitleEn)}
                helperText={touched.jobTitleEn && errors.jobTitleEn}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="Khmer Description"
                {...getFieldProps("jobDescriptionKh")}
                error={Boolean(
                  touched.jobDescriptionKh && errors.jobDescriptionKh
                )}
                helperText={touched.jobDescriptionKh && errors.jobDescriptionKh}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CssTextField
                fullWidth
                label="English Description"
                {...getFieldProps("jobDescriptionEn")}
                error={Boolean(
                  touched.jobDescriptionEn && errors.jobDescriptionEn
                )}
                helperText={touched.jobDescriptionEn && errors.jobDescriptionEn}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                sx={{ p: 2, backgroundColor: "#4838eb" }}
                fullWidth
              >
                {controllButton}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
